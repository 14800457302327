import React from 'react';
import { Props } from './allKongX.types';
import { KongXCollectionInfo } from '../kongXCollectionInfo';
import { KongXFilters, KongXFiltersData } from './kongXFilters';
import { KongXTable, KongXTableRow } from './kongXTable';
import { kongXRowToMobileCard, kongXToTableRow } from '../../../helpers/transformers';
import KongZApiHelper from '../../../helpers/apiImmKongz/KongZApiHelper';
import { KongType } from '../../../constants/kongs';
import { MobileKongs } from '../../../components/mobileKongs';
import FiltersHelper from '../../../helpers/FiltersHelper';
import { AppContext } from '../../../global/context/appContext';

export const AllKongX: React.FunctionComponent<Props> = ({ kongXDataList }) => {
  const { isMobile } = React.useContext(AppContext);
  const [filters, setFilters] = React.useState<KongXFiltersData>({});
  const [tradeVolume, setTradeVolume] = React.useState(0);
  const [rows, setRows] = React.useState<KongXTableRow[]>([]);

  React.useEffect(() => {
    //the endpoint does not work. Commented out for now
    // updateTradeVolume();
  }, []);

  React.useEffect(() => {
    const allRows = kongXDataList.map((data) => kongXToTableRow(data));
    const filteredRows = FiltersHelper.filterKongXRows(allRows, filters);
    setRows(filteredRows);
  }, [filters, kongXDataList]);

  const updateTradeVolume = async () => {
    setTradeVolume(await KongZApiHelper.tradeVolume(KongType.KONGX));
  };

  const fusedCount = kongXDataList.length;
  const mintedCount = kongXDataList.filter((kong) => kong.rarity > 0).length;

  return (
    <div style={{ height: 500, width: '90%', margin: 'auto' }}>
      <KongXCollectionInfo fusionCount={fusedCount} tokensMinted={mintedCount} totalVolume={tradeVolume} />
      <KongXFilters filters={filters} setFilters={setFilters} />
      {isMobile ? <MobileKongs rows={rows} transformToCard={kongXRowToMobileCard} /> : <KongXTable rows={rows} />}
    </div>
  );
};
