import React from 'react';
import { UserData } from '../../models/kongzApiTypes';

type Context = {
  user: UserData | undefined;
  updateUser: () => void;
  isMobile: boolean;
};

export const AppContext = React.createContext({} as Context);
