import React, { ReactElement } from 'react';
import { Props } from './appRoute.types';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import {
  ROOT_ROUTE,
  KONG_ARCHIVE_ROUTE,
  MAIN_HUB_ROUTE,
  FUSION_LAB_ROUTE,
  KONG_ARCHIVE_KONGX_ROUTE,
  DISCORD_ROUTE,
  MARKETPLACE_ROUTE,
} from '../../../constants/routes';
import { MainHub } from '../../../views/mainHub';
import { KongArchive } from '../../../views/kongArchive';
import { FusionLab } from '../../../views/fusionLab';
import { NotFound } from '../../../views/notFound/notFound';
import { KongXPage } from '../../../views/kongXPage';
import { Discord } from '../../../views/discord';
import { Marketplace } from '../../../views/marketplace';

const AppRoute: React.FunctionComponent<Props> = ({ wallet }) => {
  const resolveAuthorizedRoutes = (path: string, element: ReactElement) => {
    const routeElement = wallet !== null ? element : <Navigate to={KONG_ARCHIVE_ROUTE} replace />;
    return <Route path={path} element={routeElement} />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROOT_ROUTE} element={<Navigate to={KONG_ARCHIVE_ROUTE} replace />} />
        {resolveAuthorizedRoutes(MAIN_HUB_ROUTE, <MainHub />)}
        {resolveAuthorizedRoutes(FUSION_LAB_ROUTE, <FusionLab />)}
        <Route path={KONG_ARCHIVE_ROUTE} element={<KongArchive />} />
        <Route path={KONG_ARCHIVE_KONGX_ROUTE()} element={<KongXPage />} />
        <Route path={DISCORD_ROUTE} element={<Discord />} />
        <Route path={MARKETPLACE_ROUTE} element={<Marketplace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
