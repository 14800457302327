import React from 'react';
import { Props } from './allReports.types';
import FiltersHelper from '../../../helpers/FiltersHelper';
import {
  WhitelistPurchaseFilters,
  WhitelistPurchaseTableFilters
} from '../../../components/whitelistPurchaseTableFilters';
import { WhitelistPurchaseData } from '../../../components/whitelistCard';
import { WhitelistPurchaseTable } from '../../../components/whitelistPurchaseTable';

export const AllReports: React.FunctionComponent<Props> = ({allWhitelistPurchases}) => {
  const [filters, setFilters] = React.useState<WhitelistPurchaseFilters>({});
  const [rows, setRows] = React.useState<WhitelistPurchaseData[]>([]);

  React.useEffect(() => {
    const filteredRows = FiltersHelper.filterWhitelistPurchaseRows(allWhitelistPurchases, filters);
    setRows(filteredRows);
  }, [filters, allWhitelistPurchases]);

  return (
    <div style={{ height: 500, width: '90%', margin: 'auto' }}>
      <WhitelistPurchaseTableFilters filters={filters} setFilters={setFilters} />
      <WhitelistPurchaseTable rows={rows} />
    </div>
  );
};
