import { FuserData } from '../../../../models/kongzApiTypes';

export type Props = {
  rows: KongXTableRow[];
};

export type KongXTableRow = {
  id: number;
  url: string;
  rarity?: number;
  firstFuser: FuserData;
  secondFuser: FuserData;
  imagePfpUrl: string;
  exonite: number;
};

export enum Column {
  IMG = 'IMG',
  ID = '#ID',
  RARITY = 'Rarity',
  FUSER_1 = 'Fuser #1',
  FUSER_2 = 'Fuser #2',
  EXONITE_ORES = 'Exonite ores',
  LINK = 'Link',
}
