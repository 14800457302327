import React from 'react';
import { MenuIcon, Props } from './menuIcons.types';
import kongArchiveActiveIcon from './archive_icon_active.png';
import kongArchiveInactiveIcon from './archive_icon_inactive.png';
import mainHubActiveIcon from './mainHub_icon_active.png';
import mainHubInactiveIcon from './mainHub_icon_inactive.png';
import fusionLabActiveIcon from './fusion_lab_active.png';
import fusionLabInactiveIcon from './fusion_lab_inactive.png';
import marketplaceActiveIcon from './marketplace_active.png';
import marketplaceInactiveIcon from './marketplace_inactive.png';
import lockIcon from './lock.png';
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material';

export const MenuIcons: React.FunctionComponent<Props> = ({ isActive, name }) => {
  let icon: string | null;
  switch (name) {
    case MenuIcon.KONG_ARCHIVE:
      icon = isActive ? kongArchiveActiveIcon : kongArchiveInactiveIcon;
      break;
    case MenuIcon.MAIN_HUB:
      icon = isActive ? mainHubActiveIcon : mainHubInactiveIcon;
      break;
    case MenuIcon.LOCK:
      icon = lockIcon;
      break;
    case MenuIcon.FUSION_LAB:
      icon = isActive ? fusionLabActiveIcon : fusionLabInactiveIcon;
      break;
    case MenuIcon.MARKETPLACE:
      icon = isActive ? marketplaceActiveIcon : marketplaceInactiveIcon;
      break;
    default:
      icon = null;
  }

  return icon ? <img alt={name.toString()} src={icon} /> : <ImageSearchIcon />;
};
