import React from 'react';
import { KongDataFilters, Props } from './kongDataTableFilters.types';
import { Grid } from '@mui/material';
import { InputField } from '../textField';
import { AccordionWrapper } from '../accordionWrapper';

export const KongDataTableFilters: React.FunctionComponent<Props> = ({ filters, setFilters }) => {
  const handleChange = (prop: keyof KongDataFilters) => (filter: number | string) => {
    setFilters({ ...filters, [prop]: filter === '' ? undefined : filter });
  };

  const dropdownOptionAny = { value: '', label: 'Any' };

  const earringsDropdownOptions = ['Joker', 'Alpha', 'Bravo', 'Charlie', 'Delta', null].map((label) => {
    return label !== null ? { value: label.toLowerCase(), label: label.toLowerCase() } : dropdownOptionAny;
  });

  const fusionsDropdownOptions = [0, 1, 2, 3, 4, null].map((label) => {
    return label !== null ? { value: label + '', label: label + '' } : dropdownOptionAny;
  });

  const item = (element: React.ReactNode, sm: number) => (
    <Grid item sm={sm} xs={12}>
      {element}
    </Grid>
  );

  return (
    <AccordionWrapper summary="Filters">
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {item(<InputField label="Token ID" numbersOnly onChange={handleChange('tokenId')} />, 4)}
        {item(
          <InputField
            label="Earrings"
            onChange={handleChange('earring')}
            options={earringsDropdownOptions}
            defaultValue={''}
            isUncontrolled
          />,
          4
        )}
        {item(
          <InputField
            label="Fusions"
            onChange={handleChange('fusions')}
            options={fusionsDropdownOptions}
            defaultValue={''}
            isUncontrolled
          />,
          4
        )}
        {item(
          <InputField
            numbersOnly
            label="Hyper (more than)"
            onChange={handleChange('hyperMore')}
            isUncontrolled
          />,
          3
        )}
        {item(
          <InputField
            numbersOnly
            label="Hyper (less than)"
            onChange={handleChange('hyperLess')}
            isUncontrolled
          />,
          3
        )}
        {item(
          <InputField
            numbersOnly
            label="Rarity (more than)"
            onChange={handleChange('rarityMore')}
            isUncontrolled
          />,
          3
        )}
        {item(
          <InputField
            numbersOnly
            label="Rarity (less than)"
            onChange={handleChange('rarityLess')}
            isUncontrolled
          />,
          3
        )}
      </Grid>
    </AccordionWrapper>
  );
};
