import React from 'react';
import { KongXCardData, Props } from './kongXCard.types';
import { KongCard } from '../kongCard';
import { Box, CardActions } from '@mui/material';
import { kongXImagePath } from '../../../helpers/kongx/kongXUtils';
import { KONG_ARCHIVE_KONGX_ROUTE } from '../../../constants/routes';
import { KongCardData } from '../kongCard.types';

export const KongXCard: React.FunctionComponent<Props> = ({ kong, components, borderColor }) => {
  const fullImage = kong.imageUrl;
  const pfpImage = kong.pfpImageUrl;

  const [isHovered, setIsHovered] = React.useState(false);

  const createBaseKong = (kongX: KongXCardData, image: string): KongCardData => {
    return {
      tokenId: kongX.tokenId,
      name: kongX.name,
      imageUrl: image,
      rarity: kongX.rarity,
      type: kongX.type,
    };
  };

  const [baseKong, setBaseKong] = React.useState<KongCardData>(createBaseKong(kong, kongXImagePath(fullImage)));

  React.useEffect(() => {
    const image = isHovered ? pfpImage : fullImage;
    setBaseKong(createBaseKong(kong, kongXImagePath(image)));
  }, [isHovered]);

  const componentsToAdd = components === undefined ? [] : components;

  const fuserImage = (imageUrl: string, fuser: 'one' | 'two') => {
    const style = fuser === 'one' ? { margin: 'auto', marginLeft: 0 } : { margin: 'auto', marginRight: 0 };

    return <img alt={`fuser-${fuser}`} src={imageUrl} height={45} width={45} style={style} />;
  };

  const fuserImages = (
    <CardActions key={kong.name + '-x-card-actions'} sx={{ p: 0, pt: 0.5, pb: 0.6, m: 'auto' }}>
      {fuserImage(kong.fuserOne.imageUrl, 'one')}
      {'#' + kong.fuserOne.tokenId} + {'#' + kong.fuserTwo.tokenId}
      {fuserImage(kong.fuserTwo.imageUrl, 'two')}
    </CardActions>
  );

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <KongCard
        kong={baseKong}
        borderColor={borderColor}
        details={[]}
        link={KONG_ARCHIVE_KONGX_ROUTE(kong.tokenId.toString())}
        components={[fuserImages, ...componentsToAdd]}
      />
    </Box>
  );
};
