import React from 'react';
import { Props } from './purchaseWhitelistModal.types';
import { Box, Button, Modal, Typography } from '@mui/material';
import { anyErrorToMessage } from '../../helpers/transformers';
import { ProjectColors } from '../../themes/mainTheme';
import { AppContext } from '../../global/context/appContext';
import { InputField } from '../textField';
import { MODAL_STYLES, WALLET_LENGTH } from '../../constants/components';
import KongzSignedApiHelper from '../../helpers/apiImmKongz/KongzSignedApiHelper';
import { CURRENCY_COLOR } from '../../models/kongzApiTypes';
import { WarningAmber } from '@mui/icons-material';

export const PurchaseWhitelistModal: React.FunctionComponent<Props> = ({
  whitelist,
  isOpen,
  onClose,
  onSuccess,
  onFailure,
}) => {
  const { user } = React.useContext(AppContext);
  const { isMobile } = React.useContext(AppContext);
  const [address, setAddress] = React.useState('');
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [walletNotification, setWalletNotification] = React.useState<React.ReactElement | undefined>(undefined);

  const walletNotificationBlock: React.ReactElement = (
    <Box component="div" sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
      <WarningAmber sx={{ color: CURRENCY_COLOR[whitelist.currency] }}/>
      <Typography
        id="wallet-notification"
        sx={{ color: CURRENCY_COLOR[whitelist.currency], pl: 1 }}
      >
        Purchase will be made to unknown wallet
      </Typography>
    </Box>
  );

  React.useEffect(() => {
    if (user !== undefined && user.wallet.length === WALLET_LENGTH) {
      setAddress(user.wallet);
    }
  }, [user]);

  React.useEffect(() => {
    if (address.length === WALLET_LENGTH) {
      setIsButtonDisabled(false);

      if (user !== undefined && address !== user.wallet) {
        setWalletNotification(walletNotificationBlock);
        return;
      }

      setWalletNotification(undefined);
      return;
    }

    setIsButtonDisabled(true);
  }, [address]);

  const purchaseWhitelist = async () => {
    if (user === undefined || address.length !== WALLET_LENGTH ) {
      return;
    }

    try {
      await KongzSignedApiHelper.purchaseWhitelist(user.wallet, whitelist.id, address);

      onSuccess(whitelist);
    } catch (e: any) {
      onFailure(whitelist, anyErrorToMessage(e));
    }

    closeModal();
  };

  const closeModal = () => {
    setIsButtonDisabled(false);
    setWalletNotification(undefined);

    if (user !== undefined && user.wallet.length === WALLET_LENGTH) {
      setAddress(user.wallet);
    }

    onClose();
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={MODAL_STYLES(ProjectColors.MAIN, isMobile, '600px')}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {`Purchase whitelist for ${whitelist.spotPrice} ${whitelist.currency}`}
        </Typography>
        <Typography
          id="one-purchase-notification"
          align="center"
          sx={{ mt: 2, color: ProjectColors.GREY, p:1 }}
          fontSize={'0.7rem'}
          border="1px solid"
        >
          Only one purchase is allowed per User, regardless of which wallet purchase is made for
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Provide address you want to purchase whitelist for
        </Typography>
        <Box component="div" sx={{ pt: 3 }}>
          <InputField
            label={"Purchase for wallet (yours by default)"}
            onChange={(event) => setAddress(event.toString())}
            defaultValue={address}
          />
        </Box>
        {walletNotification}
        <Box component="div" sx={{ mt: 1 }}>
          <Button variant="contained" disabled={isButtonDisabled} onClick={purchaseWhitelist}>
            Purchase
          </Button>
          <Button
            variant="outlined"
            onClick={closeModal}
            sx={{ ml: 2, pl: 3, pr: 3, color: ProjectColors.MAIN, borderColor: ProjectColors.MAIN }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
