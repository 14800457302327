export const ROOT_ROUTE = '/';
export const KONG_ARCHIVE_ROUTE = '/kongArchive';
export const MAIN_HUB_ROUTE = '/mainHub';
export const MAIN_HUB_KONGZ_ROUTE = MAIN_HUB_ROUTE + '/kongz';
export const KONG_ARCHIVE_KONGX_ROUTE = (id: string = ':id') => KONG_ARCHIVE_ROUTE + `/kongx/${id}`;
export const FUSION_LAB_ROUTE = '/fusionLab';
export const TREASURY_ROUTE = '/treasury';
export const ARSENAL_ROUTE = '/arsenal';
export const DISCORD_ROUTE = '/discord';
export const MARKETPLACE_ROUTE = '/marketplace';
