import React from 'react';
import { Props } from './tableCell.types';
import { SxProps, TableCell as TableCellMui, Theme, Typography } from '@mui/material';
import { ProjectColors } from '../../themes/mainTheme';

export const TableCell: React.FunctionComponent<Props> = ({
  value,
  width= undefined,
  color = undefined,
  wrapInTypography = true,
  paddingX = undefined,
  textTransform = undefined,
}) => {
  const cellSx: SxProps<Theme> = { border: '2px solid', borderColor: ProjectColors.WHITE, padding: 0 };
  let typographySx: SxProps<Theme> = null;

  if (width !== undefined) {
    cellSx.width = width;
  }

  if (color !== undefined) {
    cellSx.color = color;
  }

  if (paddingX !== undefined) {
    cellSx.px = paddingX;
  }

  if (textTransform !== undefined) {
    typographySx = { textTransform: textTransform };
  }

  return (
    <TableCellMui align="center" sx={cellSx}>
      {wrapInTypography ? <Typography variant="body1" sx={typographySx}>{value}</Typography> : value}
    </TableCellMui>
  );
};
