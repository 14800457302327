import React, { ReactElement } from 'react';
import { Props } from './kongButtonCardActions.types';
import { Button, CardActions } from '@mui/material';
import arrowUpIconSrc from '../currencyTransferButton/arrow_up.png';
import arrowDownIconSrc from '../currencyTransferButton/arrow_down.png';
import { StartMiningButton } from '../startMiningButton';
import { CurrencyTransferButton, CurrencyTransferButtonType } from '../currencyTransferButton';
import { TransferModal } from '../transferModal';
import { KONGZ_TYPE_CONTRACT } from '../../constants/kongs';
import { AppContext } from '../../global/context/appContext';
import { Exonite } from '../exonite';
import { Currency } from '../../models/kongzApiTypes';
import { Hyper } from '../hyper';

export const KongButtonCardActions: React.FunctionComponent<Props> = ({
  kong,
  onTransferSuccess,
  onTransferFailure,
  onEnableMiningSuccess,
  onEnableMiningFailure,
  onCurrencyClaimSuccess,
  onCurrencyClaimFailure,
  onCurrencyLoadSuccess,
  onCurrencyLoadFailure,
}) => {
  const { user } = React.useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const currency = kong.currency

  const onTransferKongSuccess = (toWallet: string) => {
    onTransferSuccess(toWallet, kong.tokenId.toString(), kong.type);
  };

  const onTransferKongFailure = (toWallet: string, reason: string) => {
    onTransferFailure(toWallet, kong.tokenId.toString(), kong.type, reason);
  };

  const buttonsCardActions = (buttons: ReactElement[], key: string, paddingTop: number = 0.5) => {
    return (
      <CardActions key={`card-action-${key}`} sx={{ pl: 0, pr: 0, pt: paddingTop, pb: 0.5, m: 0 }}>
        {buttons}
      </CardActions>
    );
  };

  const fromKongIcon = <img alt="from-kong-to-wallet-icon" src={arrowUpIconSrc} width="32" height="32" />;
  const toKongIcon = <img alt="from-wallet-to-kong-icon" src={arrowDownIconSrc} width="32" height="32" />;

  let walletButton: JSX.Element;

  switch (currency) {
    case Currency.HYPER:
      walletButton = <Hyper key="hyper-wallet" amount={kong.currencyAmount} tooltip="Kong's Hyper wallet" />
      break;
    case Currency.EXONITE:
      walletButton = <Exonite key="exonite-wallet" amount={kong.currencyAmount} tooltip="Kong's Exonite wallet" />
      break;
    default:
      walletButton = <div></div>
  }

  const wallet = buttonsCardActions(
    [walletButton],
    'kong-wallet-actions',
    0
  );
  const miningButton = buttonsCardActions(
    [
      <StartMiningButton
        key={'start-mining-button'}
        kong={kong}
        onSuccess={onEnableMiningSuccess}
        onFailure={onEnableMiningFailure}
      />,
    ],
    'mining',
    1
  );
  const currencyTransferButtons = buttonsCardActions(
    [
      <CurrencyTransferButton
        key="kong_to_user"
        type={CurrencyTransferButtonType.KONG_TO_USER}
        kong={kong}
        user={user}
        buttonText={fromKongIcon}
        buttonsInRow={2}
        texts={{
          tooltip: `Transfer ${currency} from ${kong.type} to your Wallet`,
          modalTitle: 'Transfer Out',
          modalBody: `Enter the amount of ${currency} you want to transfer from your ${kong.type} into your own Wallet`,
        }}
        onSuccess={onCurrencyClaimSuccess}
        onFailure={onCurrencyClaimFailure}
      />,
      <CurrencyTransferButton
        key="user_to_kong"
        type={CurrencyTransferButtonType.USER_TO_KONG}
        kong={kong}
        user={user}
        buttonText={toKongIcon}
        texts={{
          tooltip: `Transfer ${currency} from Wallet to your ${kong.type}`,
          modalTitle: 'Transfer In',
          modalBody: `Enter the amount of ${currency} you want to transfer from your own Wallet into the ${kong.type}`,
        }}
        buttonsInRow={2}
        onSuccess={onCurrencyLoadSuccess}
        onFailure={onCurrencyLoadFailure}
      />,
    ],
    'loag-claim'
  );
  const kongTransferButton = buttonsCardActions(
    [
      <Button
        key="transfer-kong"
        size="small"
        variant="outlined"
        onClick={() => setIsModalOpen(true)}
        sx={{ minWidth: '100%', minHeight: 35, pl: 0, pr: 0 }}
      >
        Transfer NFT
      </Button>,
    ],
    'imx-actions',
    1
  );

  return kong.rarity > 0 ? (
    <div>
      {wallet}
      {miningButton}
      {currencyTransferButtons}
      {kongTransferButton}
      <TransferModal
        key="transfer-kong-modal"
        tokenId={kong.tokenId.toString()}
        contractAddress={KONGZ_TYPE_CONTRACT[kong.type]}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={onTransferKongSuccess}
        onFailure={onTransferKongFailure}
      />
    </div>
  ) : (<div></div>);
};
