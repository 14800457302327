import { WhitelistCardData } from '../whitelistCard';
import { AddWhitelistRequestData, EditWhitelistRequestData } from '../../models/whitelistApiTypes';

export type Props = {
  whitelist: WhitelistCardData | undefined;
  isOpen: boolean;
  onClose: () => void;
  onApprove: (whitelistRequestData: EditWhitelistRequestData | AddWhitelistRequestData) => void;
};

export type IsDiscordRequiredModalOption = {
  value: string;
  label: string;
};

export const IsDiscordRequiredModalOptions: IsDiscordRequiredModalOption[] = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' },
];
