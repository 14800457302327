import { Alert, AlertColor, Grid, Snackbar } from '@mui/material';
import React from 'react';
import { Props } from './mainHub.types';
import { OwnedKongXCard } from './ownedKongXCard';
import { OwnedKongZCard } from './ownedKongZCard';
import { AppContext } from '../../global/context/appContext';
import { kongZDataToOwnedCard, kongXDataToOwnedCard } from '../../helpers/transformers';
import { KongType } from '../../constants/kongs';
import { NOTIFICATION_TIMEOUT } from '../../constants/components';
import { Currency } from '../../models/kongzApiTypes';
import { KongCardData } from '../../components/kongCard';

export const MainHub: React.FunctionComponent<Props> = () => {
  const { user, updateUser } = React.useContext(AppContext);
  const [notification, setNotification] = React.useState<React.ReactElement | undefined>(undefined);

  const onTransferSuccess = (toWallet: string, tokenId: string, type: string) => {
    const success = createNotification(
      'success',
      `Successfully transferred token ${type} #${tokenId} to wallet ${toWallet}`
    );
    setNotification(success);
    updateUser();
  };

  const onTransferFailure = (toWallet: string, tokenId: string, type: string, reason: string) => {
    const failure = createNotification(
      'error',
      `Failed to transfer token ${type} #${tokenId} to wallet ${toWallet}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const onEnableMiningSuccess = (kong: KongCardData) => {
    const success = createNotification(
      'success',
      `Mining successfully enabled for ${kong.type} #${kong.tokenId}`
    );
    setNotification(success);
    updateUser();
  };

  const onEnableMiningFailure = (kong: KongCardData, reason: string) => {
    const failure = createNotification(
      'error',
      `Failed to enable mining for ${kong.type} #${kong.tokenId}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const onCurrencyLoadSuccess = (
    currency: Currency,
    amount: number,
    fromWallet: string,
    toType: KongType,
    toId: number
  ) => {
    const success = createNotification(
      'success',
      `Successfully loaded ${amount} $${currency} from ${fromWallet} to ${toType} #${toId}`
    );
    setNotification(success);
    updateUser();
  };

  const onCurrencyLoadFailure = (
    currency: Currency,
    amount: number,
    fromWallet: string,
    toType: KongType,
    toId: number,
    reason: string
  ) => {
    const failure = createNotification(
      'error',
      `Failed to load ${amount} $${currency} from ${fromWallet} to ${toType} #${toId}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const onCurrencyClaimSuccess = (
    currency: Currency,
    amount: number,
    toWallet: string,
    fromType: KongType,
    fromId: number
  ) => {
    const success = createNotification(
      'success',
      `Successfully claimed ${amount} $${currency} from ${fromType} #${fromId} to ${toWallet}`
    );
    setNotification(success);
    updateUser();
  };

  const onCurrencyClaimFailure = (
    currency: Currency,
    amount: number,
    toWallet: string,
    fromType: KongType,
    fromId: number,
    reason: string
  ) => {
    const failure = createNotification(
      'error',
      `Failed to claim ${amount} $${currency} from ${fromType} #${fromId} to ${toWallet}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const closeNotification = () => setNotification(undefined);

  const createNotification = (severityValue: AlertColor, text: string) => {
    return (
      <Alert onClose={closeNotification} severity={severityValue} variant="filled" sx={{ width: '100%' }}>
        {text}
      </Alert>
    );
  };

  let kongZCardsCount = 0;
  const kongZCards = () => {
    if (user === undefined) {
      return [];
    }

    const kongZCardsData = user.ownedKongZGeneralData.map((kongz) => kongZDataToOwnedCard(kongz, KongType.KONGZ));
    const legendCardsData = user.ownedKongZLegendGeneralData.map((legend) =>
      kongZDataToOwnedCard(legend, KongType.KONGZ_LEGEND)
    );

    const allKongZCards = [...kongZCardsData, ...legendCardsData];
    kongZCardsCount = kongZCards.length;

    return allKongZCards;
  };

  const kongXCards = () => {
    if (user === undefined) {
      return [];
    }

    return user.ownedKongXGeneralData.map((kongX) => kongXDataToOwnedCard(kongX, KongType.KONGX));
  };

  return (
    <div>
      <Snackbar open={notification !== undefined} autoHideDuration={NOTIFICATION_TIMEOUT} onClose={closeNotification}>
        {notification}
      </Snackbar>
      <Grid container spacing={3}>
        {kongZCards().map((card, index) => (
          <Grid item xs="auto" key={index}>
            <OwnedKongZCard
              kong={card}
              onTransferSuccess={onTransferSuccess}
              onTransferFailure={onTransferFailure}
              onEnableMiningSuccess={onEnableMiningSuccess}
              onEnableMiningFailure={onEnableMiningFailure}
              onCurrencyClaimSuccess={onCurrencyClaimSuccess}
              onCurrencyClaimFailure={onCurrencyClaimFailure}
              onCurrencyLoadSuccess={onCurrencyLoadSuccess}
              onCurrencyLoadFailure={onCurrencyLoadFailure}
            />
          </Grid>
        ))}
        {kongXCards().map((card, index) => (
          <Grid item xs="auto" key={kongZCardsCount + index}>
            <OwnedKongXCard
              kong={card}
              onTransferSuccess={onTransferSuccess}
              onTransferFailure={onTransferFailure}
              onEnableMiningSuccess={onEnableMiningSuccess}
              onEnableMiningFailure={onEnableMiningFailure}
              onCurrencyClaimSuccess={onCurrencyClaimSuccess}
              onCurrencyClaimFailure={onCurrencyClaimFailure}
              onCurrencyLoadSuccess={onCurrencyLoadSuccess}
              onCurrencyLoadFailure={onCurrencyLoadFailure}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
