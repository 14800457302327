import React from 'react';
import { Props } from './discordMenu.types';
import { FaDiscord } from 'react-icons/all';
import { ProjectColors } from "../../../themes/mainTheme";
import {DISCORD_AUTHORIZE_URL, DISCORD_SERVER_URL, DiscordButton} from "../../../constants/discord";
import { AppContext } from "../../../global/context/appContext";
import DiscordApiHelper from "../../../helpers/DiscordApiHelper";
import { Button, Menu, MenuItem } from "@mui/material";

export const DiscordMenu: React.FunctionComponent<Props> = ({ isConnected, wallet}) => {
  const { user } = React.useContext(AppContext);

  const connectToDiscord = async () => {
    const state = DiscordApiHelper.generateDiscordAuthorizationState();
    const discordAuthorizationUrl = DISCORD_AUTHORIZE_URL + `&state=${btoa(state)}`;

    window.open(discordAuthorizationUrl, "_self");
  };

  const openDiscord = () => {
    window.open(DISCORD_SERVER_URL, "_blank");
    setAnchorEl(null);
  };

  const iconStyle = { width: '4em', height: '3em' };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttonColor, setButtonColor] = React.useState(ProjectColors.WHITE);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (isConnected) {
      setButtonColor(ProjectColors.SECONDARY);
    }
  }, [user, isConnected]);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <FaDiscord color={buttonColor} style={iconStyle} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={openDiscord}>{DiscordButton.OPEN}</MenuItem>
        {wallet && !isConnected && (
          <MenuItem onClick={connectToDiscord}>{DiscordButton.CONNECT}</MenuItem>
        )}
        {isConnected && (
          <MenuItem onClick={connectToDiscord}>{DiscordButton.CONNECT_NEW}</MenuItem>
        )}
      </Menu>
    </div>
  );
};
