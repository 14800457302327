import React from 'react';
import { Props } from './fusionTrigger.types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { EXPECTED_FUSERS_COUNT } from '../fusers';
import { KongZCardData } from '../../../components/kongCard/kongZCard';
import { ProjectColors } from '../../../themes/mainTheme';
import { KongType } from '../../../constants/kongs';
import selectKongImage from './select_kong_placeholder.png';
import KongzSignedApiHelper from '../../../helpers/apiImmKongz/KongzSignedApiHelper';
import { anyErrorToMessage } from '../../../helpers/transformers';
import { KONG_ACTION_BUTTON_MIN_HEIGHT } from '../../../constants/components';

export const FusionTrigger: React.FunctionComponent<Props> = ({ selectedKongs, wallet, onSuccess, onFailure }) => {
  const unknownLabel = 'unknown';

  const emptyKong: KongZCardData = {
    tokenId: 0,
    name: unknownLabel,
    imageUrl: selectKongImage,
    rarity: 0,
    earrings: unknownLabel,
    fusionCount: 0,
    type: KongType.KONGZ,
  };

  let fusers = selectedKongs;
  while (fusers.length < EXPECTED_FUSERS_COUNT) {
    fusers = [...fusers, emptyKong];
  }

  const isButtonDisabled = () => selectedKongs.length !== EXPECTED_FUSERS_COUNT;

  const triggerFusion = async () => {
    const [kongA, kongB] = selectedKongs;
    try {
      await KongzSignedApiHelper.fuse(kongA.type, kongA.tokenId, kongB.type, kongB.tokenId, wallet);
      onSuccess();
    } catch (e: any) {
      onFailure(anyErrorToMessage(e));
    }
  };

  return (
    <Box sx={{ mb: 0, pb: 2, borderBottom: `1px solid ${ProjectColors.MAIN}` }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {fusers.map((fuser, index) => (
          <Grid item xs="auto" key={index}>
            <Box sx={{ maxWidth: '50%', maxHeight: '50%' }}>
              <img
                alt="selected-fuser"
                src={fuser.imageUrl}
                width="300"
                height="300"
                style={{ padding: 0.5, border: `1px solid ${ProjectColors.MAIN}` }}
              />
            </Box>
          </Grid>
        ))}
        <Grid item xs="auto">
          <Box maxWidth={300}>
            <Typography sx={{ m: 1 }}>Click below to trigger the fusion process</Typography>
            <Button
              variant={isButtonDisabled() ? 'outlined' : 'contained'}
              disabled={isButtonDisabled()}
              onClick={() => triggerFusion()}
              sx={{
                ':disabled': {
                  backgroundColor: ProjectColors.BLACK,
                  borderColor: ProjectColors.MAIN,
                  color: ProjectColors.MAIN,
                },
                backgroundColor: ProjectColors.MAIN,
                minHeight: KONG_ACTION_BUTTON_MIN_HEIGHT,
                p: 3,
                m: 1,
              }}
            >
              Activate fusion core
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
