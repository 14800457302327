import React, { useEffect } from 'react';
import './App.css';
import { Props } from './App.types';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { mainTheme } from '../../themes/mainTheme';
import { TopBar } from './header/topBar';
import { Menu } from './menu';
import AppRoute from './appRoute/appRoute';
import { Page } from './page';
import { WalletHelper } from '../../helpers/WalletHelper';
import { Web3Helper } from '../../helpers/Web3Helper';
import { WalletAlert } from './walletAlert';
import { AppContext } from '../context/appContext';
import KongZApiHelper from '../../helpers/apiImmKongz/KongZApiHelper';
import { UserData } from '../../models/kongzApiTypes';
import { CLOSE_DRAWER_WIDTH, MOBILE_WIDTH } from '../../constants/components';
import StoreTmpDataHelper from '../../helpers/StoreTmpDataHelper';
import { MISSING_PROVIDER_STORAGE_KEY } from '../../constants/login';

export const App: React.FunctionComponent<Props> = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [wallet, setWallet] = React.useState(WalletHelper.getStoredWallet());
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [isWalletAccessible, setIsWalletAccessible] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [user, setUser] = React.useState<UserData | undefined>(undefined);
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    handleAlert();
    handleResize();
  }, []);

  useEffect(() => {
    updateUser();
  }, [wallet]);

  function getWindowWidth() {
    const { innerWidth: windowWidth } = window;
    const { clientWidth } = document.documentElement;
    return Math.min(windowWidth, clientWidth);
  }

  const handleResize = () => {
    const width = getWindowWidth();
    setIsMobile(width < MOBILE_WIDTH);
    if (isDrawerOpen && width < CLOSE_DRAWER_WIDTH) {
      setIsDrawerOpen(false);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  const handleAlert = async () => {
    try {
      await new Web3Helper();
      setIsWalletAccessible(true);
    } catch (e: any) {
      if (StoreTmpDataHelper.getStored(MISSING_PROVIDER_STORAGE_KEY) === null) {
        StoreTmpDataHelper.store(MISSING_PROVIDER_STORAGE_KEY, true.toString());
        setAlertOpen(true);
        setAlertMessage(e.message);
        saveWallet(null);
      }
    }
  };

  const updateUser = async () => {
    const updatedUser = wallet ? await KongZApiHelper.getUserData(wallet) : undefined;
    setUser(updatedUser);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const saveWallet = (walletToSave: string | null) => {
    setWallet(walletToSave);
    WalletHelper.storeWallet(walletToSave);
  };

  const initializeContext = () => {
    return { user: user, updateUser: updateUser, isMobile: isMobile };
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppContext.Provider value={initializeContext()}>
          <TopBar
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            wallet={wallet}
            saveWallet={saveWallet}
            isWalletAccessible={isWalletAccessible}
          />
          <Menu isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} wallet={wallet} setWallet={saveWallet} />
          <Page component={<AppRoute wallet={wallet} />} />
          <WalletAlert text={alertMessage} isOpen={alertOpen} onClosed={closeAlert} />
        </AppContext.Provider>
      </Box>
    </ThemeProvider>
  );
};
