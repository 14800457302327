import {
  Currency,
  CURRENCY_NAME,
  KongXDataWithBalances,
  KongXDataWithMiningBalances,
  KongZData,
  KongZDataWithBalances,
  KongZDataWithMiningBalances,
  LegendData,
  LegendDataWithBalances,
  LegendDataWithMiningBalances,
} from '../models/kongzApiTypes';
import { KongDataTableRow } from '../components/kongDataTable';
import { OwnedKongZCardData } from '../views/mainHub/ownedKongZCard';
import { OwnedKongXCardData } from '../views/mainHub/ownedKongXCard';
import { KongType } from '../constants/kongs';
import { KongZCardData } from '../components/kongCard/kongZCard';
import { KongXTableRow } from '../views/kongArchive/allKongX/kongXTable';
import { KONG_ARCHIVE_KONGX_ROUTE } from '../constants/routes';
import { MobileKongCardData } from '../components/mobileKongs';
import { WhitelistCardData, WhitelistPurchaseData } from '../components/whitelistCard';
import { WhitelistData, WhitelistPurchase } from '../models/whitelistApiTypes';

export type fromTo<X extends string | number | symbol, Y> = { [_ in X]: Y };

export const kongDataToTableRow = (data: KongZDataWithBalances | LegendDataWithBalances): KongDataTableRow => {
  const balance = data.balances !== undefined ? data.balances[Currency.HYPER] : undefined;

  return {
    id: data.tokenId,
    hyper: balance === undefined ? 0 : balance.amount,
    earring: data.earrings,
    rarity: data.rarity,
    fusions: data.fusionCount,
    imageUrl: data.imageUrl,
    url: data.url,
  };
};

export const kongZDataToOwnedCard = (
  data: KongZDataWithMiningBalances | LegendDataWithMiningBalances,
  type: KongType
): OwnedKongZCardData => {
  const balance = data.balances !== undefined ? data.balances[Currency.HYPER] : undefined;
  const [amount, isMining, miningEnabledAt] =
    balance === undefined ? [0, false, '-'] : [balance.amount, balance.isMiningEnabled, balance.miningEnabledAt];

  return {
    tokenId: data.tokenId,
    name: data.name,
    imageUrl: data.imageUrl,
    rarity: data.rarity,
    earrings: data.earrings,
    fusionCount: data.fusionCount,
    currency: Currency.HYPER,
    currencyAmount: amount,
    isMiningCurrency: isMining,
    miningSince: miningEnabledAt,
    type: type,
  };
};

export const kongDataToCard = (data: KongZData | LegendData, type: KongType): KongZCardData => {
  return {
    tokenId: data.tokenId,
    name: data.name,
    imageUrl: data.imageUrl,
    rarity: data.rarity,
    earrings: data.earrings,
    fusionCount: data.fusionCount,
    type: type,
  };
};

export const kongXDataToOwnedCard = (data: KongXDataWithMiningBalances, type: KongType): OwnedKongXCardData => {
  const balance = data.balances !== undefined ? data.balances[Currency.EXONITE] : undefined;
  const [amount, isMining, miningEnabledAt] =
    balance === undefined ? [0, false, '-'] : [balance.amount, balance.isMiningEnabled, balance.miningEnabledAt];

  return {
    currency: Currency.EXONITE,
    currencyAmount: amount,
    isMiningCurrency: isMining,
    miningSince: miningEnabledAt,
    tokenId: data.tokenId,
    name: data.name,
    imageUrl: data.imageUrl,
    pfpImageUrl: data.pfpImageUrl,
    rarity: data.rarity,
    type: type,
    fuserOne: data.firstFuserData,
    fuserTwo: data.secondFuserData
  };
};

export const kongXToTableRow = (kongX: KongXDataWithBalances): KongXTableRow => {
  const balance = kongX.balances !== undefined ? kongX.balances[Currency.EXONITE] : undefined;

  return {
    id: kongX.tokenId,
    url: KONG_ARCHIVE_KONGX_ROUTE(kongX.tokenId.toString()),
    rarity: kongX.rarity,
    firstFuser: kongX.firstFuserData,
    secondFuser: kongX.secondFuserData,
    imagePfpUrl: kongX.metadata.pfpImageUrl,
    exonite: balance === undefined ? 0 : balance.amount,
  };
};

export const kongXRowToMobileCard = (row: KongXTableRow): MobileKongCardData => {
  return {
    tokenId: row.id,
    name: '',
    imageUrl: row.imagePfpUrl,
    rarity: row.rarity === undefined ? 0 : row.rarity,
    type: KongType.KONGX,
    details: [
      { text: 'Exonite > ' + row.exonite, type: 'body1' },
      { text: '#' + row.firstFuser.tokenId + ' + #' + row.secondFuser.tokenId, type: 'body1' },
    ],
    link: KONG_ARCHIVE_KONGX_ROUTE(row.id.toString()),
  };
};

export const kongZRowToMobileCard = (row: KongDataTableRow): MobileKongCardData => {
  return {
    tokenId: row.id,
    name: '',
    imageUrl: row.imageUrl,
    rarity: row.rarity,
    type: KongType.KONGZ,
    details: [
      { text: 'Hyper > ' + row.hyper, type: 'body1' },
      { text: 'Fusions > ' + row.fusions, type: 'body1' },
    ],
    link: row.url,
  };
};

export const whitelistDataToWhitelistCardData = (data: WhitelistData): WhitelistCardData => {
  return {
    id: data.uniqueId,
    name: data.name,
    description: data.description,
    image: data.imageUrl,
    spotPrice: data.spotPrice,
    currency: CURRENCY_NAME[data.currency],
    totalSpots: data.totalSpots,
    isDiscordRequired: data.isDiscordRequired,
    discordLink: data.discordLink ? data.discordLink : undefined,
    websiteLink: data.websiteLink ? data.websiteLink : undefined,
    twitterLink: data.twitterLink ? data.twitterLink : undefined,
    availableSpots: data.purchases !== undefined ? data.totalSpots - data.purchases.length : data.totalSpots,
    spotHolders: data.purchases !== undefined ? data.purchases.map(({user}) => (user)) : [],
  }
}

export const whitelistsDataToWhitelistPurchasesData = (whitelistsData: WhitelistData[]): WhitelistPurchaseData[] => {
  const convertPurchaseData = (purchaseData: WhitelistPurchase, whitelistData: WhitelistData) => {
    return {
      user: purchaseData.user,
      discordId: purchaseData.discordId !== 'null' ? String(purchaseData.discordId) : '',
      purchasedFor: purchaseData.purchasedFor,
      purchasedAt: new Date(purchaseData.madeAt),
      id: whitelistData.uniqueId,
      name: whitelistData.name,
      currency: CURRENCY_NAME[whitelistData.currency],
      spotsLeft: whitelistData.purchases !== undefined
        ? whitelistData.totalSpots - whitelistData.purchases.length
        : whitelistData.totalSpots,
    }
  }

  return whitelistsData.map(
    (whitelistData) => whitelistData.purchases !== undefined ? whitelistData.purchases.map(
      (purchaseData) => convertPurchaseData(purchaseData, whitelistData)
    ) : []
  ).flat().sort(
    (objA, objB) => objB.purchasedAt.getTime() - objA.purchasedAt.getTime(),
  )
}

export const anyErrorToMessage = (e: any) => (e && e.response ? e.response.data.message : e.message);
