import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { Props } from './walletAlert.types';

export const WalletAlert: React.FunctionComponent<Props> = ({ text, isOpen, onClosed }) => {
  return (
    <Dialog open={isOpen} onClose={onClosed}>
      <DialogTitle id="wallet-alert-title">Ethereum wallet alert</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosed}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
