import { ProjectColors } from '../themes/mainTheme';

export const NOTIFICATION_TIMEOUT = 12000;
export const MOBILE_WIDTH = 1000;
export const CLOSE_DRAWER_WIDTH = 1300;
export const KONG_ACTION_BUTTON_MIN_HEIGHT = 45;
export const WHITELIST_ACTION_BUTTON_MIN_HEIGHT = 45;
export const CARD_ACTION_DEFAULT_ELEMENT_WIDTH = 300;
export const WALLET_LENGTH = 42;
export const FILE_UPLOAD_MAX_BYTE_SIZE = 1000000;

export const MODAL_STYLES = (borderColor: ProjectColors, isMobile: boolean, defaultWidth: string = '470px') => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: `2px solid ${borderColor}`,
    boxShadow: 24,
    p: 4,
    width: isMobile ? '95%' : defaultWidth,
  };
};
