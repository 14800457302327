import { DISCORD_OAUTH_STATE_STORAGE_KEY } from '../views/discord';
import axios from 'axios';
import {
  DISCORD_APP_CLIENT_ID,
  DISCORD_APP_CLIENT_SECRET,
  DISCORD_APP_REDIRECT_URL,
  DISCORD_OAUTH_TOKEN_URL,
  DISCORD_USER_DATA_URL
} from '../constants/discord';
import { DiscordOauthData } from '../models/DiscordApiTypes';
import * as queryString from 'querystring';
import KongzSignedApiHelper from './apiImmKongz/KongzSignedApiHelper';

class DiscordApiHelper {
  private static instance: DiscordApiHelper;

  public static getInstance(): DiscordApiHelper {
    if (!DiscordApiHelper.instance) {
      DiscordApiHelper.instance = new DiscordApiHelper();
    }

    return DiscordApiHelper.instance;
  }

  public generateDiscordAuthorizationState() {
    const randomArray = crypto.getRandomValues(new Uint32Array(1));
    const authorizationState = randomArray[0].toString(16);

    localStorage.setItem(DISCORD_OAUTH_STATE_STORAGE_KEY, authorizationState);

    return authorizationState;
  }

  public async authorizeDiscordUser(wallet: string) {
    const discordRedirectUriFragment = new URLSearchParams(window.location.search.slice());
    const state = discordRedirectUriFragment.get('state');
    const code = discordRedirectUriFragment.get('code');

    if (!state) {
      throw Error('Incorrect Discord authorization state');
    }
    if (!code) {
      throw Error('Incorrect Discord authorization code');
    }

    if (localStorage.getItem(DISCORD_OAUTH_STATE_STORAGE_KEY) !== atob(decodeURIComponent(state))) {
      throw Error('Incorrect Discord authorization state');
    }

    localStorage.removeItem(DISCORD_OAUTH_STATE_STORAGE_KEY);

    const oauthData: DiscordOauthData = {
      'client_id': DISCORD_APP_CLIENT_ID,
      'client_secret': DISCORD_APP_CLIENT_SECRET,
      'grant_type': 'authorization_code',
      'code': code,
      'redirect_uri': DISCORD_APP_REDIRECT_URL
    };

    const oauthResponse = await axios.post(DISCORD_OAUTH_TOKEN_URL, queryString.stringify(oauthData), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const userAccessToken = oauthResponse.data.access_token;
    const tokenType = oauthResponse.data.token_type;

    const userData = await axios.get(DISCORD_USER_DATA_URL, {
      headers: {
        authorization: `${tokenType} ${userAccessToken}`,
      },
    });

    await KongzSignedApiHelper.connectUserToDiscordUser(wallet, userData.data.id);
  }
}

export default DiscordApiHelper.getInstance();
