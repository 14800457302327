type DataToStore = {
  data: string;
  savedAt: string;
};

class StoreTmpDataHelper {
  private static instance: StoreTmpDataHelper;

  public static getInstance(): StoreTmpDataHelper {
    if (!StoreTmpDataHelper.instance) {
      StoreTmpDataHelper.instance = new StoreTmpDataHelper();
    }

    return StoreTmpDataHelper.instance;
  }

  public store(key: string, data: string | null) {
    if (null !== data) {
      const currentDate = new Date().toISOString().slice(0, 10);

      const walletDataToStore: DataToStore = { data: data, savedAt: currentDate };
      localStorage.setItem(key, JSON.stringify(walletDataToStore));

      return;
    }

    localStorage.removeItem(key);
  }

  public getStored(key: string): string | null {
    const data = localStorage.getItem(key);

    if (data === null) {
      return null;
    }

    const storedDataObject: DataToStore = JSON.parse(data);
    const currentDate = new Date().toISOString().slice(0, 10);

    if (storedDataObject.savedAt === null || storedDataObject.savedAt !== currentDate) {
      localStorage.removeItem(key);

      return null;
    }

    return storedDataObject.data;
  }
}

export default StoreTmpDataHelper.getInstance()