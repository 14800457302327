import React from 'react';
import { Props } from './transferModal.types';
import { Box, Button, Modal, Typography } from '@mui/material';
import { ERC721TokenType, Link } from '@imtbl/imx-sdk';
import { IMX_LINK_LINK } from '../../constants/imx';
import { anyErrorToMessage } from '../../helpers/transformers';
import { ProjectColors } from '../../themes/mainTheme';
import { AppContext } from '../../global/context/appContext';
import { InputField } from '../textField';
import { MODAL_STYLES, WALLET_LENGTH } from '../../constants/components';

export const TransferModal: React.FunctionComponent<Props> = ({
  tokenId,
  contractAddress,
  isOpen,
  onClose,
  onSuccess,
  onFailure,
}) => {
  const { isMobile } = React.useContext(AppContext);
  const [address, setAddress] = React.useState('');
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    if (address.length === WALLET_LENGTH) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [address]);

  const performTransfer = async () => {
    const link = new Link(IMX_LINK_LINK);

    try {
      await link.setup({});
    } catch (e) {
      //to catch rejection after link setup
    }

    const params = [
      {
        type: ERC721TokenType.ERC721,
        tokenId: tokenId,
        tokenAddress: contractAddress,
        toAddress: address,
      },
    ];

    await link
      .transfer(params)
      .then(() => onSuccess(address))
      .catch((e) => onFailure(address, anyErrorToMessage(e)));

    setAddress('');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={MODAL_STYLES(ProjectColors.MAIN, isMobile, '600px')}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Transfer NFT
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Provide address you want to transfer this Kong to.
        </Typography>
        <Box component="div" sx={{ pt: 2 }}>
          <InputField
            label="Transfer to wallet"
            onChange={(event) => setAddress(event.toString())}
          />
        </Box>
        <Box component="div" sx={{ mt: 1 }}>
          <Button variant="contained" disabled={isButtonDisabled} onClick={performTransfer}>
            Approve transfer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
