import axios from 'axios';
import {
  KONGZ_API_ALL_KONGX_URL,
  KONGZ_API_ALL_KONGZ_URL,
  KONGZ_API_ALL_LEGENDS_URL,
  KONGZ_API_WHITELISTS_URL,
  KONGZ_API_KONGX_URL,
  KONGZ_API_MINING_COUNT,
  KONGZ_API_TRADE_VOLUME,
  KONGZ_API_USER_URL,
} from '../../constants/kongzApiUrls';
import {
  Currency,
  KONG_TYPE_TO_API_TYPE,
  KongXDataWithBalances,
  KongZDataWithBalances,
  LegendDataWithBalances,
  UserData,
} from '../../models/kongzApiTypes';
import { KongType } from '../../constants/kongs';
import { roundToDecimal } from '../utils';
import { WhitelistData } from '../../models/whitelistApiTypes';

class KongZApiHelper {
  private static instance: KongZApiHelper;

  public static getInstance(): KongZApiHelper {
    if (!KongZApiHelper.instance) {
      KongZApiHelper.instance = new KongZApiHelper();
    }

    return KongZApiHelper.instance;
  }

  public async getAllKongZData(): Promise<KongZDataWithBalances[]> {
    const response = await axios.get(KONGZ_API_ALL_KONGZ_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getAllLegendsData(): Promise<LegendDataWithBalances[]> {
    const response = await axios.get(KONGZ_API_ALL_LEGENDS_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getAllKongXData(): Promise<KongXDataWithBalances[]> {
    const response = await axios.get(KONGZ_API_ALL_KONGX_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getKongXData(id: number): Promise<KongXDataWithBalances> {
    const response = await axios.get(KONGZ_API_KONGX_URL(id), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getUserData(wallet: string): Promise<UserData> {
    const response = await axios.get(KONGZ_API_USER_URL(wallet), {
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data;
  }

  public async miningCount(kongType: KongType, currency: Currency): Promise<number> {
    const apiKongType = KONG_TYPE_TO_API_TYPE[kongType];
    const response = await axios.get(KONGZ_API_MINING_COUNT(apiKongType, currency), {
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data;
  }

  public async tradeVolume(kongType: KongType): Promise<number> {
    const apiKongType = KONG_TYPE_TO_API_TYPE[kongType];
    const response = await axios.get(KONGZ_API_TRADE_VOLUME(apiKongType), {
      headers: { 'Content-Type': 'application/json' },
    });

    return roundToDecimal(response.data);
  }

  public async getAllWhitelists(): Promise<WhitelistData[]> {
    const response = await axios.get(KONGZ_API_WHITELISTS_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default KongZApiHelper.getInstance();
