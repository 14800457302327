import React from 'react';
import { Props } from './kongZCard.types';
import { KongCard } from '../kongCard';

export const KongZCard: React.FunctionComponent<Props> = ({ kong, components, borderColor }) => {
  return (
    <KongCard
      kong={kong}
      borderColor={borderColor}
      details={[
        { text: 'ER > ' + kong.earrings, type: 'body1' },
        { text: `Fused > ${kong.fusionCount} times`, type: 'body1' },
      ]}
      components={components}
    />
  );
};
