import React, { ReactElement } from 'react';
import { Props } from './whitelistCard.types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import { PurchaseWhitelistButton } from '../purchaseWhitelistButton';
import { ProjectColors } from '../../themes/mainTheme';
import { CURRENCY_COLOR, UserRole } from '../../models/kongzApiTypes';
import { EditWhitelistButton } from '../editWhitelistButton';
import { DeleteWhitelistButton } from '../deleteWhitelistButton';
import { AppContext } from '../../global/context/appContext';
import { CARD_ACTION_DEFAULT_ELEMENT_WIDTH } from '../../constants/components';
import { Launch, Twitter } from '@mui/icons-material';
import { FaDiscord } from 'react-icons/all';

export const WhitelistCard: React.FunctionComponent<Props> = ({
  whitelist,
  isWhitelistAvailable,
  onPurchaseSuccess,
  onPurchaseFailure,
  onEditSuccess,
  onEditFailure,
  onDeleteSuccess,
  onDeleteFailure,
}) => {
  const { user } = React.useContext(AppContext);
  const [manageWhitelistButtons, setManageWhitelistButtons] = React.useState<undefined | JSX.Element>(undefined);

  const maxDescriptionCompactFullLength = 100;
  const maxDescriptionContentLength = 97;

  React.useEffect(() => {
    updateManageWhitelistButtons();
  }, [user]);

  const buttonsCardActions = (buttons: ReactElement[], key: string, paddingTop: number = 0.5) => {
    return (
      <CardActions key={`card-action-${key}`} sx={{ pl: 0, pr: 0, pt: paddingTop, pb: 0.5, m: 0 }}>
        {buttons}
      </CardActions>
    );
  };

  const purchaseButton = buttonsCardActions(
    [
      <PurchaseWhitelistButton
        key={'purchase-whitelist-button'}
        whitelist={whitelist}
        isWhitelistAvailable={isWhitelistAvailable}
        onSuccess={onPurchaseSuccess}
        onFailure={onPurchaseFailure}
      />,
    ],
    'purchase-whitelist',
    1
  );

  const updateManageWhitelistButtons = () => {
    if (user === undefined || !user.roles.includes(UserRole.IS_WHITELIST_MANAGER)) {
      setManageWhitelistButtons(undefined);
      return;
    }

    setManageWhitelistButtons(
      buttonsCardActions(
        [
          <EditWhitelistButton
            key={'edit-whitelist-button'}
            whitelist={whitelist}
            onSuccess={onEditSuccess}
            onFailure={onEditFailure}
          />,
          <DeleteWhitelistButton
            key={'delete-whitelist-button'}
            whitelist={whitelist}
            onSuccess={onDeleteSuccess}
            onFailure={onDeleteFailure}
          />,
        ],
        'manage-whitelist',
        0.5
      )
    );
  }

  const borderColor = isWhitelistAvailable ? ProjectColors.MAIN : ProjectColors.GREY;

  const cardMedia = () => {
    return (
      <CardMedia
        component="img"
        alt={whitelist.name}
        image={whitelist.image}
        sx={{ width: CARD_ACTION_DEFAULT_ELEMENT_WIDTH, height: 300, padding: 0.5, border: `1px solid ${borderColor}` }}
      />
    );
  };

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: CARD_ACTION_DEFAULT_ELEMENT_WIDTH,
      borderColor: ProjectColors.EXONITE,
    },
  });

  const compactDescription = whitelist.description.length > maxDescriptionCompactFullLength
    ? whitelist.description.substring(0, maxDescriptionContentLength) + ' ...'
    : whitelist.description

  const linkField = (value: string, type: string, icon: JSX.Element) => {
    return (
      <IconButton
        href={value}
        target={'_blank'}
        aria-label={`${type} link`}
        sx={{px: 0, pl: 0.5}}
      >
        {icon}
      </IconButton>
    );
  }

  return (
    <Card sx={{ width: CARD_ACTION_DEFAULT_ELEMENT_WIDTH }}>
      {cardMedia()}
      <CardContent sx={{ border: `1px solid ${borderColor}` }}>
        <Box>
          <Typography
            key={whitelist.id + '-name-' + whitelist.name}
            variant={'h5'}
            color={isWhitelistAvailable ? 'text.secondary' : 'text.disabled'}
            fontSize={'0.8rem'}
            textAlign={'center'}
            sx={{
              pb: 0.5,
              height: 50,
              wordBreak: 'break-word',
              whiteSpace: 'normal',
            }}
          >
            {whitelist.name}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={11}>
            <Box>{[
              (
                <StyledTooltip
                  title={whitelist.description}
                  key={whitelist.id + '-description-tooltip-' + whitelist.description}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography
                      key={whitelist.id + '-description-' + whitelist.description}
                      variant={'h6'}
                      color={isWhitelistAvailable ? undefined : 'text.disabled'}
                      fontSize={'0.7rem'}
                      sx={{
                        pb: 1,
                        height: 100,
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {compactDescription}
                    </Typography>
                  </Box>
                </StyledTooltip>
              ),
              (
                <Typography
                  key={whitelist.id + '-spot-price-block'}
                  variant={'h6'}
                  color={isWhitelistAvailable ? CURRENCY_COLOR[whitelist.currency] : ProjectColors.GREY}
                  fontSize={'0.8rem'}
                  sx={{
                    pb: 0.5,
                    my: 1
                  }}
                >
                  {`Spot price > ${whitelist.spotPrice}`}
                </Typography>
              ),
              (
                <Typography
                  key={whitelist.id + '-spots-' + whitelist.availableSpots}
                  variant={'h6'}
                  color={isWhitelistAvailable ? undefined : ProjectColors.GREY}
                  fontSize={'0.8rem'}
                  sx={{
                    pb: 0.5,
                    my: 1
                  }}
                >
                  {`Available > ${whitelist.availableSpots} / ${whitelist.totalSpots}`}
                </Typography>
              )
            ]}</Box>
          </Grid>
          <Grid item xs={1}>
            {
              whitelist.twitterLink
                ? linkField(whitelist.twitterLink, 'twitter', <Twitter sx={{color: ProjectColors.SECONDARY}}/>)
                : undefined
            }
            {
              whitelist.discordLink
                ? linkField(whitelist.discordLink, 'discord', <FaDiscord color={ProjectColors.SECONDARY}/>)
                : undefined
            }
            {
              whitelist.websiteLink
                ? linkField(whitelist.websiteLink, 'website', <Launch sx={{color: ProjectColors.SECONDARY}}/>)
                : undefined
            }
              </Grid>
        </Grid>
        <Box sx={{ pt: 1 }}>
          {[
            purchaseButton,
            manageWhitelistButtons,
          ]}
        </Box>
      </CardContent>
    </Card>
  );
};
