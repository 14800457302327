import React from 'react';
import { AppContext } from '../../global/context/appContext';
import { Props } from './DiscordMenuResolver.types';
import { DiscordMenu } from './discordMenu';
import { DiscordMobileMenu } from './discordMobileMenu';

export const DiscordMenuResolver: React.FunctionComponent<Props> = ({ isConnected, wallet}) => {
  const { isMobile } = React.useContext(AppContext);

  return isMobile ? (
    <DiscordMobileMenu isConnected={isConnected} wallet={wallet}/>
  ) : (
    <DiscordMenu isConnected={isConnected} wallet={wallet}/>
  )
}
