import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { Props } from './deleteWhitelistModal.types';
import { AppContext } from '../../global/context/appContext';
import { MODAL_STYLES } from '../../constants/components';
import { ProjectColors } from '../../themes/mainTheme';

export const DeleteWhitelistModal: React.FunctionComponent<Props> = ({
  whitelist,
  isOpen,
  onClose,
  onDelete,
}) => {
  const { isMobile } = React.useContext(AppContext);

  const performDeletion = () => {
    onDelete();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={MODAL_STYLES(ProjectColors.RED, isMobile)}>
        <Typography id="delete-whitelist-modal-title" variant="h6" component="h2">
          Delete whitelist
        </Typography>
        <Typography id="delete-whitelist-modal-description" sx={{ mt: 2 }}>
          {`Are you sure you want to delete Whitelist ${whitelist.name}?`}
        </Typography>
        <Box sx={{ display: 'inline-flex', pt: 2 }}>
          <Button
            variant="contained"
            onClick={performDeletion}
            sx={{ ml: 2, pl: 3, pr: 3, backgroundColor: ProjectColors.RED }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ ml: 2, pl: 3, pr: 3, color: ProjectColors.RED, borderColor: ProjectColors.RED }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
