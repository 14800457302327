import { MENU_WIDTH, Props } from './menu.types';
import React from 'react';
import {
  Avatar,
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { FUSION_LAB_ROUTE, KONG_ARCHIVE_ROUTE, MAIN_HUB_ROUTE, MARKETPLACE_ROUTE } from '../../../constants/routes';
import { ProjectColors } from '../../../themes/mainTheme';
import { MenuIcon } from './menuIcons';
import { MenuIcons } from './menuIcons';
import { ConnectWalletButton } from '../../../components/connectWalletButton';
import { AppContext } from '../../context/appContext';
import { DiscordMenuResolver } from '../../../components/discord';
import { LOGIN_TOOLTIP } from '../../../constants/login';

export const Menu: React.FunctionComponent<Props> = ({ isDrawerOpen, setIsDrawerOpen, wallet, setWallet }) => {
  const { user, isMobile } = React.useContext(AppContext);

  const openedMixin = (theme: Theme): CSSObject => ({
    width: MENU_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: MENU_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
      borderColor: ProjectColors.HYPER,
    },
  });

  type MenuItem = {
    name: string;
    icon?: MenuIcon;
    link: string;
    tooltip?: string;
  };

  const replaceByName = (menu: MenuItem[], itemToReplace: MenuItem) => {
    return menu.map((item) => (item.name === itemToReplace.name ? itemToReplace : item));
  };

  let mainMenuItems: MenuItem[] = [
    { name: 'Kong Archive', icon: MenuIcon.KONG_ARCHIVE, link: KONG_ARCHIVE_ROUTE },
    { name: 'Marketplace', icon: MenuIcon.MARKETPLACE, link: MARKETPLACE_ROUTE },
    { name: 'Main Hub', icon: MenuIcon.LOCK, link: '', tooltip: LOGIN_TOOLTIP },
    { name: 'Fusion Lab', icon: MenuIcon.LOCK, link: '', tooltip: LOGIN_TOOLTIP },
    { name: 'Treasury', icon: MenuIcon.LOCK, link: '' },
    { name: 'Arsenal', icon: MenuIcon.LOCK, link: '' },
  ];

  if (wallet !== null) {
    mainMenuItems = replaceByName(mainMenuItems, { name: 'Main Hub', icon: MenuIcon.MAIN_HUB, link: MAIN_HUB_ROUTE });
    mainMenuItems = replaceByName(mainMenuItems, {
      name: 'Fusion Lab',
      icon: MenuIcon.FUSION_LAB,
      link: FUSION_LAB_ROUTE,
    });
  }

  const menuItemsToListItems = (menuItems: MenuItem[]) => {
    return menuItems.map((item) => {
      return menuItemToListItem(item);
    });
  };

  const listItemText = (name: string, isBold: boolean, sx: SxProps<Theme> | null = null) => {
    return isBold ? (
      <ListItemText sx={sx} disableTypography={true} primary={<Typography variant="body2">{name}</Typography>} />
    ) : (
      <ListItemText sx={sx} primary={name} />
    );
  };

  const menuItemToListItem = (item: MenuItem) => {
    const itemSx = {};

    if (item.link === undefined) {
      throw Error('Unknown menu list error');
    }

    const isActive = window.location.pathname === item.link;
    const sx = isActive ? { color: ProjectColors.MAIN } : {};
    const listItemIcon = item.icon !== undefined && (
      <ListItemIcon key={item.name + '-icon'} sx={sx}>
        <MenuIcons isActive={isActive} name={item.icon} />
      </ListItemIcon>
    );

    const itemButton =
      item.link !== '' ? (
        <ListItemButton key={item.name + '-button'} component={Link} href={item.link} selected={isActive} sx={itemSx}>
          {listItemIcon}
          {listItemText(item.name, isActive, sx)}
        </ListItemButton>
      ) : (
        <ListItemButton key={item.name + '-button'} sx={itemSx}>
          {listItemIcon}
          {listItemText(item.name, isActive, sx)}
        </ListItemButton>
      );

    return item.tooltip === undefined ? (
      itemButton
    ) : (
      <StyledTooltip key={item.name + '-tooltip'} title={item.tooltip} disableInteractive>
        {itemButton}
      </StyledTooltip>
    );
  };

  const theme = useTheme();

  const saveWallet = (walletToSave: string) => {
    setWallet(walletToSave);
    setIsDrawerOpen(false);
  };

  const resolveDrawer = (elements: React.ReactNode) => {
    return isMobile ? (
      <MuiDrawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        {elements}
        <ConnectWalletButton isMobile setWallet={saveWallet} isConnected={wallet !== null} />
      </MuiDrawer>
    ) : (
      <Drawer variant="permanent" open={isDrawerOpen}>
        {elements}
      </Drawer>
    );
  };

  return resolveDrawer(
    <div>
      <DrawerHeader>
        <Avatar src="/logo512.png" />
        <Typography variant="body2" sx={{ margin: 2, color: ProjectColors.MAIN }}>
          Immutable Kongz
        </Typography>
        <IconButton onClick={() => setIsDrawerOpen(false)}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuItemsToListItems(mainMenuItems)}
        {isMobile && (
          <DiscordMenuResolver isConnected={user !== undefined && user.discordUser !== null} wallet={wallet}/>
        )}
      </List>
    </div>
  );
};
