import { fromTo } from '../helpers/transformers';
import { FuserType, KongType } from '../constants/kongs';
import { ProjectColors } from '../themes/mainTheme';

export enum Currency {
  HYPER = 'HYPER',
  EXONITE = 'EXONITE',
}

export const CURRENCY_COLOR: fromTo<Currency, ProjectColors> = {
  [Currency.HYPER]: ProjectColors.HYPER,
  [Currency.EXONITE]: ProjectColors.EXONITE,
};

export const CURRENCY_NAME: fromTo<string, Currency> = {
  [Currency.HYPER]: Currency.HYPER,
  [Currency.EXONITE]: Currency.EXONITE,
}

export enum Type {
  KONGZ = 'KONGZ',
  KONGZ_LEGENDS = 'KONGZ_LEGENDS',
  USERS = 'USERS',
  KONGX = 'KONGX',
}

export type BalanceData = {
  uniqueId: string;
  amount: number;
  currency: Currency;
};

export type Balances = {
  [Currency.HYPER]?: BalanceData;
  [Currency.EXONITE]?: BalanceData;
};

export type FuserGeneralData = {
  tokenId: number;
  name: string;
  imageUrl: string;
};

export type KongGeneralData = FuserGeneralData & {
  rarity: number;
  earrings: string;
  fusionCount: number;
  url: string;
};

export type KongZData = KongGeneralData;
export type LegendData = KongGeneralData;

export type KongXMetadataTraits = {
  baseColor: TraitData | null;
  skinColor: TraitData | null;
  patternType: TraitData | null;
  patternColor: TraitData | null;
  background: TraitData | null;
  chestAccessory: TraitData | null;
  frame: TraitData | null;
};

export type KongXMetadata = {
  name: string;
  imageUrl: string;
  pfpImageUrl: string;
  tokenId: number;
  uniqueId: string;
} & KongXMetadataTraits;

export type TraitData = {
  name: string;
  appearanceCount: number;
  appearancePercentage: number;
};

export type KongZDataWithBalances = {
  balances?: Balances;
} & KongZData;

export type LegendDataWithBalances = {
  balances?: Balances;
} & LegendData;

export type BalanceDataWithMining = {
  isMiningEnabled: boolean;
  miningEnabledAt: string;
} & BalanceData;

export type BalancesDataWithMining = {
  [Currency.HYPER]?: BalanceDataWithMining;
};

export type KongZDataWithMiningBalances = {
  balances?: BalancesDataWithMining;
} & KongZData;

export type LegendDataWithMiningBalances = {
  balances?: BalancesDataWithMining;
} & LegendData;

export type KongXData = {
  tokenId: number;
  url: string;
  metadata: KongXMetadata;
  firstFuserData: FuserData;
  secondFuserData: FuserData;
  rarity: number;
};

export type KongXDataWithBalances = {
  balances?: Balances;
} & KongXData;

export type OwnedKongXGeneralData = {
  tokenId: number;
  url: string;
  name: string;
  imageUrl: string;
  pfpImageUrl: string;
  firstFuserData: FuserData;
  secondFuserData: FuserData;
  rarity: number;
};

export type ExoniteBalancesDataWithMining = {
  [Currency.EXONITE]?: BalanceDataWithMining;
};

export type KongXDataWithMiningBalances = {
  balances?: ExoniteBalancesDataWithMining;
} & OwnedKongXGeneralData;

export type FuserData = {
  type: FuserType;
  tokenId: number;
  name: string;
  imageUrl: string;
};

export type UserData = {
  wallet: string;
  ownedKongZGeneralData: KongZDataWithMiningBalances[];
  ownedKongZLegendGeneralData: KongZDataWithMiningBalances[];
  ownedKongXGeneralData: KongXDataWithMiningBalances[];
  roles: UserRole[];
  balances?: Balances;
  discordUser?: string;
};

export enum UserRole {
  IS_WHITELIST_MANAGER = 'IS_WHITELIST_MANAGER',
}

export type MiningData = {
  forType: Type;
  forId: string;
};

export type KongToUserTransferData = {
  fromType: Type;
  fromId: string;
  amount: number;
};

export type UserToKongTransferData = {
  toType: Type;
  toId: string;
  amount: number;
};

export type FusionData = {
  firstType: Type;
  firstId: string;
  secondType: Type;
  secondId: string;
};

export type WhitelistPurchaseData = {
  purchaseFor: string;
};

export const KONG_TYPE_TO_API_TYPE: fromTo<KongType, Type> = {
  [KongType.KONGZ]: Type.KONGZ,
  [KongType.KONGZ_LEGEND]: Type.KONGZ_LEGENDS,
  [KongType.KONGX]: Type.KONGX,
};
