export const roundToDecimal = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const buttonsBoxWidth = (buttonsInRow: number) => {
  const marginLeft = buttonsInRow === 1 ? 0 : 8;
  return `calc(${100 / buttonsInRow}% - ${marginLeft / buttonsInRow}px)`;
}

export const byteToMegabyte = (bytes: number): number => {
  return bytes / 1000000;
}

export const isValidHttpUrl = (checkedUrl: string) => {
  try {
    const url = new URL(checkedUrl);

    return url.protocol === "http:" || url.protocol === "https:";
  } catch (e: any) {
    return false;
  }
}
