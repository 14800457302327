import React from 'react';
import { Props } from './fileField.types';
import { TextField } from '@mui/material';

export const FileField: React.FunctionComponent<Props> = ({
  label,
  onChange,
  color,
  defaultValue,
  minWidth = '100%',
}) => {
  const sx = {
    minWidth: minWidth,
    '& .MuiInputBase-input': { height: 'auto' },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: color,
      },
    },
    '& label.Mui-focused': {
      color: color,
    },
    '& label': {
      color: color,
    },
  };

  return (
    <TextField
      sx={sx}
      label={label}
      onChange={(event) => onChange((event.target as HTMLInputElement))}
      variant="outlined"
      size="medium"
      type="file"
      fullWidth
      defaultValue={defaultValue}
      InputLabelProps={{ shrink: true }}
      inputProps={{accept: 'image/png'}}
    >
    </TextField>
  );
};
