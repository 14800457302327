import React from 'react';
import { Props } from './editWhitelistButton.types';
import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import KongzSignedApiHelper from '../../helpers/apiImmKongz/KongzSignedApiHelper';
import { anyErrorToMessage } from '../../helpers/transformers';
import { ProjectColors } from '../../themes/mainTheme';
import {
  CARD_ACTION_DEFAULT_ELEMENT_WIDTH,
  FILE_UPLOAD_MAX_BYTE_SIZE as FILE_SIZE_LIMIT,
  WHITELIST_ACTION_BUTTON_MIN_HEIGHT
} from '../../constants/components';
import { LOGIN_TOOLTIP } from '../../constants/login';
import { ManageWhitelistModal } from '../manageWhitelistModal';
import { EditWhitelistRequestData } from '../../models/whitelistApiTypes';
import { buttonsBoxWidth, byteToMegabyte, isValidHttpUrl } from '../../helpers/utils';

export const EditWhitelistButton: React.FunctionComponent<Props> = ({
  whitelist,
  onSuccess,
  onFailure
}) => {
  const { user } = React.useContext(AppContext);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [buttonVariant, setButtonVariant] = React.useState<'outlined' | 'contained'>('outlined');
  const [isButtonEnabled, setButtonEnabled] = React.useState(false);
  const [tooltipText, setTooltipText] = React.useState(LOGIN_TOOLTIP);

  React.useEffect(() => {
    updateButtonAttributes();
  }, [user]);

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: CARD_ACTION_DEFAULT_ELEMENT_WIDTH,
      borderColor: ProjectColors.MAIN,
    },
  });

  const updateButtonAttributes = () => {
    if (user === undefined) {
      setButtonVariant('outlined');
      setTooltipText(LOGIN_TOOLTIP);
      setButtonEnabled(false);

      return;
    }

    setButtonVariant('contained');
    setTooltipText(
      `You are going to edit Whitelist ${whitelist.name}`
    );
    setButtonEnabled(true);
  }

  const isLinkFieldValid = (fieldValue: string | null, type: string) => {
    if (fieldValue && !isValidHttpUrl(fieldValue)) {
      onFailure(whitelist, `Invalid ${type} Link`);

      return false;
    }

    return true;
  }

  const onApprove = async (editWhitelistRequestData: EditWhitelistRequestData) => {
    if (user === undefined) {
      return;
    }

    const imageSize = editWhitelistRequestData.imageFile !== null ? editWhitelistRequestData.imageFile.size : 0;

    if (imageSize > FILE_SIZE_LIMIT) {
      onFailure(
        whitelist,
        `File exceeds limit of ${byteToMegabyte(FILE_SIZE_LIMIT)} MB. Given: ${byteToMegabyte(imageSize)} MB`
      );
      return;
    }

    if (
      !isLinkFieldValid(editWhitelistRequestData.discordLink, 'Discord')
      || !isLinkFieldValid(editWhitelistRequestData.websiteLink, 'Website')
      || !isLinkFieldValid(editWhitelistRequestData.twitterLink, 'Twitter')
    ) {
      return;
    }

    try {
      await KongzSignedApiHelper.editWhitelist(user.wallet, whitelist.id, editWhitelistRequestData);

      onSuccess(whitelist);
    } catch (e: any) {
      onFailure(whitelist, anyErrorToMessage(e));
    } finally {
      setIsModalOpen(false);
    }
  };

  const button = () => {
    return (
      <StyledTooltip title={tooltipText} disableInteractive>
        <Box>
          <Button
            disabled={!isButtonEnabled}
            size="small"
            variant={buttonVariant}
            onClick={() => setIsModalOpen(true)}
            sx={{
              ':disabled': {
                backgroundColor: ProjectColors.BLACK,
                borderColor: ProjectColors.MAIN,
                color: ProjectColors.MAIN,
              },
              backgroundColor: ProjectColors.MAIN,
              minWidth: '100%',
              minHeight: WHITELIST_ACTION_BUTTON_MIN_HEIGHT,
              pr: 1,
            }}
          >
            Edit
          </Button>
        </Box>
      </StyledTooltip>
    );
  };

  const modal = () => {
    return (
      <ManageWhitelistModal
        whitelist={whitelist}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onApprove={onApprove}
      />
    );
  };

  return (
    <Box sx={{ minWidth: buttonsBoxWidth(2) }}>
      {button()}
      {modal()}
    </Box>
  );
};
