import { Link } from '@mui/material';
import React from 'react';
import { Props } from './linkToKong.types';
import linkImage from './icon_link.png';
import linkHoverImage from './icon_link_hover.png';

export const LinkToKong: React.FunctionComponent<Props> = ({ url, id, inNewTab = false }) => {
  return (
    <Link href={url} target={`${inNewTab ? '_blank' : ''}`}>
      <img
        alt={`link to ${id.toString()}`}
        src={linkImage}
        onMouseOver={(e) => (e.currentTarget.src = linkHoverImage)}
        onMouseOut={(e) => (e.currentTarget.src = linkImage)}
        width="16"
        height="16"
      />
    </Link>
  );
};
