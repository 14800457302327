export type Props = {
  rows: KongDataTableRow[];
};

export type KongDataTableRow = {
  id: number;
  hyper: number;
  earring: string;
  rarity: number;
  fusions: number;
  imageUrl: string;
  url: string;
};

export type PageSize = 25 | 50 | 100;
export const allPageSizes: PageSize[] = [25, 50, 100];

export enum Column {
  IMG = 'IMG',
  ID = '#ID',
  RARITY = 'Rarity',
  EARRING = 'Earring',
  HYPERIUM_ORES = 'Hyperium ores',
  FUSIONS = 'Fusions',
  LINK = 'Link',
}
