import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { Props } from './currencyTransferModal.types';
import { InputField } from '../../textField';
import { AppContext } from '../../../global/context/appContext';
import { CURRENCY_COLOR } from '../../../models/kongzApiTypes';
import { MODAL_STYLES } from '../../../constants/components';

export const CurrencyTransferModal: React.FunctionComponent<Props> = ({
  currency,
  isOpen,
  onClose,
  onApprove,
  amounts,
  texts
}) => {
  const { isMobile } = React.useContext(AppContext);
  const [amount, setAmount] = React.useState(amounts.default === undefined ? 0 : amounts.default);
  const isAmountValid = () => amount > 0 && amount <= amounts.max;

  const invalidAmountWarning = () => {
    return isAmountValid() ? (
      <></>
    ) : (
      <Typography color="red" fontSize="0.6rem">
        Invalid amount of {currency} to transfer
      </Typography>
    );
  };

  const performTransfer = () => {
    onApprove(amount);
  };

  const currencyAmountLabel = `${currency} amount to send`;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={MODAL_STYLES(CURRENCY_COLOR[currency], isMobile)}>
        <Typography id="currency-transfer-modal-title" variant="h6" component="h2">
          {texts.title}
        </Typography>
        <Typography id="currency-transfer-modal-description" sx={{ mt: 2 }}>
          {texts.body}
        </Typography>
        <Box sx={{ display: 'inline-flex', pt: 2 }}>
          <InputField
            numbersOnly
            label={currencyAmountLabel}
            onChange={(event) => setAmount(+event)}
            defaultValue={amount}
            color={CURRENCY_COLOR[currency]}
            minWidth="20%"
          />
          <Button
            variant="contained"
            disabled={!isAmountValid()}
            onClick={performTransfer}
            sx={{ ml: 2, pl: 3, pr: 3, backgroundColor: CURRENCY_COLOR[currency] }}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ ml: 2, pl: 3, pr: 3, color: CURRENCY_COLOR[currency], borderColor: CURRENCY_COLOR[currency] }}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={{ pt: 0.5 }}>{invalidAmountWarning()}</Box>
      </Box>
    </Modal>
  );
};
