import { Currency, Type as ApiKongType } from '../models/kongzApiTypes';

export const KONGZ_API_URL = `${process.env.REACT_APP_KONGZ_API_URL}`;
export const KONGZ_API_USERS_URL = `${KONGZ_API_URL}/user`;
export const KONGZ_API_REGISTER_URL = `${KONGZ_API_USERS_URL}/register`;
export const KONGZ_API_FUSION_URL = `${KONGZ_API_USERS_URL}/fusion`;
export const KONGZ_API_CONNECT_DISCORD_USER_URL = (discordUser: number) =>
  `${KONGZ_API_USERS_URL}/discordUser/${discordUser}`;
export const KONGZ_API_PURCHASE_WHITELIST = (whitelistId: string) =>
  `${KONGZ_API_USERS_URL}/whitelists/${whitelistId}/purchase`;
export const KONGZ_API_USER_URL = (wallet: string) => `${KONGZ_API_USERS_URL}/${wallet}`;
export const KONGZ_API_MESSAGE_URL = `${KONGZ_API_URL}/auth/message`;
export const KONGZ_API_ALL_KONGZ_URL = `${KONGZ_API_URL}/kongz`;
export const KONGZ_API_ALL_LEGENDS_URL = `${KONGZ_API_ALL_KONGZ_URL}/legends`;
export const KONGZ_API_ALL_KONGX_URL = `${KONGZ_API_URL}/kongx`;
export const KONGZ_API_KONGX_URL = (id: number) => `${KONGZ_API_ALL_KONGX_URL}/${id}`;
export const KONGZ_API_BALANCE_URL = `${KONGZ_API_URL}/balance`;
export const KONGZ_API_START_MINING_URL = (currency: string) => `${KONGZ_API_BALANCE_URL}/${currency}/mining`;
export const KONGZ_API_CURRENCY_TRANSFER_URL = (currency: string) => `${KONGZ_API_BALANCE_URL}/${currency}/transfer`;
export const KONGZ_API_USER_TO_KONG_CURRENCY_TRANSFER_URL = (currency: string) =>
  `${KONGZ_API_CURRENCY_TRANSFER_URL(currency)}/userToKong`;
export const KONGZ_API_KONG_TO_USER_CURRENCY_TRANSFER_URL = (currency: string) =>
  `${KONGZ_API_CURRENCY_TRANSFER_URL(currency)}/kongToUser`;
export const KONGZ_API_STAT = `${KONGZ_API_URL}/stat`;
export const KONGZ_API_MINING_COUNT = (kongType: ApiKongType, currency: Currency) =>
  `${KONGZ_API_STAT}/miningCount/${kongType}/${currency}`;
export const KONGZ_API_TRADE_VOLUME = (kongType: ApiKongType) => `${KONGZ_API_STAT}/tradeVolume/${kongType}`;
export const KONGZ_API_MARKETPLACE_URL = `${KONGZ_API_URL}/marketplace`;
export const KONGZ_API_WHITELISTS_URL = `${KONGZ_API_MARKETPLACE_URL}/whitelists`;
export const KONGZ_API_ADD_WHITELIST_URL = `${KONGZ_API_WHITELISTS_URL}/add`;
export const KONGZ_API_WHITELIST_URL = (whitelistId: string) => `${KONGZ_API_WHITELISTS_URL}/${whitelistId}`;
