import React from 'react';
import { Props } from './collectionInfo.types';
import { Grid, Paper, styled, Typography } from '@mui/material';
import { ProjectColors } from '../../themes/mainTheme';
import { AccordionWrapper } from '../accordionWrapper';

export const CollectionInfo: React.FunctionComponent<Props> = ({ details }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: ProjectColors.SECONDARY,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: ProjectColors.BLACK,
  }));

  return (
    <AccordionWrapper summary="Collection Info">
      <Grid container spacing={3} marginBottom={3}>
        {Object.values(details).map((detail, index) => (
          <Grid key={index} item sm={6} xs={12} md={4}>
            <Item>
              <Typography variant="body2">{detail.label}</Typography>
              <Typography variant="body2">{detail.value}</Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
    </AccordionWrapper>
  );
};
