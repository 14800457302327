import React from 'react';
import { Props } from './startMiningButton.types';
import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import exoniteMiningAnimationSrc from '../exonite/exonite_mining_animation.gif';
import hyperMiningAnimationSrc from '../hyper/hyper_mining_animation.gif';
import notMiningIconSrc from './not_mining_icon.png';
import { AppContext } from '../../global/context/appContext';
import KongzSignedApiHelper from '../../helpers/apiImmKongz/KongzSignedApiHelper';
import { Currency, CURRENCY_COLOR, KONG_TYPE_TO_API_TYPE } from '../../models/kongzApiTypes';
import { anyErrorToMessage } from '../../helpers/transformers';
import { ProjectColors } from '../../themes/mainTheme';
import { CARD_ACTION_DEFAULT_ELEMENT_WIDTH, KONG_ACTION_BUTTON_MIN_HEIGHT } from '../../constants/components';

export const StartMiningButton: React.FunctionComponent<Props> = ({ kong, onSuccess, onFailure }) => {
  const { user } = React.useContext(AppContext);

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: CARD_ACTION_DEFAULT_ELEMENT_WIDTH,
      borderColor: CURRENCY_COLOR[kong.currency],
    },
  });

  let miningAnimationSrc: string;

  switch (kong.currency) {
    case Currency.HYPER:
      miningAnimationSrc = hyperMiningAnimationSrc
      break;
    case Currency.EXONITE:
      miningAnimationSrc = exoniteMiningAnimationSrc
      break;
    default:
      miningAnimationSrc = ''
  }

  const miningAnimation = <img alt="mining-animation" src={miningAnimationSrc} width="32" height="32" />;
  const notMiningIcon = <img alt="not-mining-icon" src={notMiningIconSrc} width="32" height="32" />;

  const enableMining = async () => {
    if (user === undefined) {
      return;
    }

    try {
      await KongzSignedApiHelper.enableMining(user.wallet, kong.currency, {
        forType: KONG_TYPE_TO_API_TYPE[kong.type],
        forId: kong.tokenId.toString(),
      });

      onSuccess(kong);
    } catch (e: any) {
      onFailure(kong, anyErrorToMessage(e));
    }
  };

  const button = (enabled: boolean) => {
    const miningDate = new Date(kong.miningSince).toDateString();
    const buttonVariant = enabled ? 'contained' : 'outlined';
    const tooltipText = enabled
      ? `You are going to start mining $${kong.currency} from now on. Based on the rules of mining $${kong.currency}` +
        ` you might retroactively receive more $${kong.currency} for previous days.`
      : `Mining is enabled since ${miningDate}`;
    const icon = enabled ? notMiningIcon : miningAnimation;
    const text = enabled ? 'Start Mining >' : 'Mining';

    return (
      <StyledTooltip title={tooltipText} disableInteractive>
        <Box sx={{ minWidth: '100%' }}>
          <Button
            disabled={!enabled}
            size="small"
            variant={buttonVariant}
            startIcon={icon}
            onClick={enableMining}
            sx={{
              ':disabled': {
                backgroundColor: ProjectColors.BLACK,
                borderColor: CURRENCY_COLOR[kong.currency],
                color: CURRENCY_COLOR[kong.currency],
              },
              backgroundColor: CURRENCY_COLOR[kong.currency],
              minWidth: '100%',
              minHeight: KONG_ACTION_BUTTON_MIN_HEIGHT,
            }}
          >
            {text}
          </Button>
        </Box>
      </StyledTooltip>
    );
  };

  return button(!kong.isMiningCurrency);
};
