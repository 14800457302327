import React from 'react';
import { Props } from './ownedKongXCard.types';
import { KongXCard } from '../../../components/kongCard/kongXCard';
import { ProjectColors } from '../../../themes/mainTheme';
import { KongButtonCardActions } from '../../../components/kongButtonCardActions';

export const OwnedKongXCard: React.FunctionComponent<Props> = ({
  kong,
  onTransferSuccess,
  onTransferFailure,
  onEnableMiningSuccess,
  onEnableMiningFailure,
  onCurrencyClaimSuccess,
  onCurrencyClaimFailure,
  onCurrencyLoadSuccess,
  onCurrencyLoadFailure,
}) => {
  return (
    <KongXCard
      key={kong.name + "-x-card"}
      kong={kong}
      borderColor={kong.isMiningCurrency ? ProjectColors.EXONITE : ProjectColors.WHITE}
      components={[
        <KongButtonCardActions
          key={kong.name + "-z-button-card-actions"}
          kong={kong}
          onTransferSuccess={onTransferSuccess}
          onTransferFailure={onTransferFailure}
          onEnableMiningSuccess={onEnableMiningSuccess}
          onEnableMiningFailure={onEnableMiningFailure}
          onCurrencyClaimSuccess={onCurrencyClaimSuccess}
          onCurrencyClaimFailure={onCurrencyClaimFailure}
          onCurrencyLoadSuccess={onCurrencyLoadSuccess}
          onCurrencyLoadFailure={onCurrencyLoadFailure}
        />
      ]}
    />
  );
};
