import React from 'react';
import { Alert, AlertColor, Box, Snackbar } from '@mui/material';
import { FusionTrigger } from './fusionTrigger';
import { AppContext } from '../../global/context/appContext';
import { kongDataToCard } from '../../helpers/transformers';
import { KongType } from '../../constants/kongs';
import { KongZCardData } from '../../components/kongCard/kongZCard';
import { EXPECTED_FUSERS_COUNT, Fusers } from './fusers';
import { contains, exclude } from '../../helpers/arrayFunctions';
import { FusionSuccessModal } from './fusionSuccessModal';
import { NOTIFICATION_TIMEOUT } from '../../constants/components';
import { Props } from './fusionLab.types';

export const FusionLab: React.FunctionComponent<Props> = () => {
  const { user, updateUser } = React.useContext(AppContext);
  const [selectedKongs, setSelectedKongs] = React.useState<KongZCardData[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [notification, setNotification] = React.useState<React.ReactElement | undefined>(undefined);

  const onSelect = (kong: KongZCardData) => {
    const editedSelectedKongs = contains(selectedKongs, kong) ? exclude(selectedKongs, kong) : [...selectedKongs, kong];

    if (editedSelectedKongs.length > EXPECTED_FUSERS_COUNT) {
      editedSelectedKongs.shift();
    }

    setSelectedKongs(editedSelectedKongs);
  };

  const cardsData = () => {
    if (user === undefined) {
      return [];
    }

    const kongZCardsData = user.ownedKongZGeneralData.map((kongz) => kongDataToCard(kongz, KongType.KONGZ));
    const legendCardsData = user.ownedKongZLegendGeneralData.map((legend) =>
      kongDataToCard(legend, KongType.KONGZ_LEGEND)
    );

    return [...kongZCardsData, ...legendCardsData];
  };

  const closeNotification = () => setNotification(undefined);

  const createNotification = (severityValue: AlertColor, text: string) => {
    return (
      <Alert onClose={closeNotification} severity={severityValue} variant="filled" sx={{ width: '100%' }}>
        {text}
      </Alert>
    );
  };

  const onSuccess = () => {
    setIsModalOpen(true);
    updateUser();
    setSelectedKongs([]);
    setNotification(createNotification('success', `KongX fusion core triggered successfully`));
  };

  const onFailure = (reason: string) => {
    setNotification(createNotification('error', `Failed to fuse KongX. Reason: ${reason}`));
  };

  return (
    <Box>
      <Snackbar open={notification !== undefined} autoHideDuration={NOTIFICATION_TIMEOUT} onClose={closeNotification}>
        {notification}
      </Snackbar>
      {user !== undefined && (
        <FusionTrigger selectedKongs={selectedKongs} onSuccess={onSuccess} onFailure={onFailure} wallet={user.wallet} />
      )}
      <Fusers kongs={cardsData()} selectedKongs={selectedKongs} onSelect={onSelect} />
      <FusionSuccessModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Box>
  );
};
