import React from 'react';
import { Props } from './purchaseWhitelistButton.types';
import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { ProjectColors } from '../../themes/mainTheme';
import { CARD_ACTION_DEFAULT_ELEMENT_WIDTH, WHITELIST_ACTION_BUTTON_MIN_HEIGHT } from '../../constants/components';
import { LOGIN_TOOLTIP } from '../../constants/login';
import { PurchaseWhitelistModal } from '../purchaseWhitelistModal';

export const PurchaseWhitelistButton: React.FunctionComponent<Props> = ({
  whitelist,
  isWhitelistAvailable,
  onSuccess,
  onFailure
}) => {
  const { user } = React.useContext(AppContext);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [buttonVariant, setButtonVariant] = React.useState<'outlined' | 'contained'>('outlined');
  const [buttonText, setButtonText] = React.useState('Sold out');
  const [isButtonEnabled, setButtonEnabled] = React.useState(false);
  const [tooltipText, setTooltipText] = React.useState('All available spots are purchased');

  React.useEffect(() => {
    updateButtonAttributes();
  }, [user]);

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: CARD_ACTION_DEFAULT_ELEMENT_WIDTH,
      borderColor: ProjectColors.MAIN,
    },
  });

  const updateButtonAttributes = () => {
    if (!isWhitelistAvailable) {
      return;
    }

    setButtonVariant('contained');
    setButtonText('Purchase');
    setTooltipText(
      `You are going to purchase Whitelist ${whitelist.name} for $${whitelist.spotPrice} ${whitelist.currency}`
    );
    setButtonEnabled(true);

    if (user === undefined) {
      setTooltipText(LOGIN_TOOLTIP);
      setButtonEnabled(false);
      setButtonVariant('outlined');

      return;
    }

    if ((user.discordUser === undefined || user.discordUser === null) && whitelist.isDiscordRequired) {
      setTooltipText('Connect Discord account to be able to purchase');
      setButtonEnabled(false);
      setButtonVariant('outlined');

      return;
    }

    if (whitelist.spotHolders.includes(user.wallet)) {
      setTooltipText('You have already purchased the spot');
      setButtonText('Purchased');
      setButtonEnabled(false);
      setButtonVariant('outlined');
    }
  }

  return (
    <>
      <StyledTooltip title={tooltipText} disableInteractive>
        <Box sx={{ minWidth: '100%' }}>
          <Button
            disabled={!isButtonEnabled}
            size="small"
            variant={buttonVariant}
            onClick={() => setIsModalOpen(true)}
            sx={{
              ':disabled': {
                backgroundColor: ProjectColors.BLACK,
                borderColor: isWhitelistAvailable ? ProjectColors.MAIN : ProjectColors.GREY,
                color: isWhitelistAvailable ? ProjectColors.MAIN : ProjectColors.GREY,
              },
              backgroundColor: ProjectColors.MAIN,
              minWidth: '100%',
              minHeight: WHITELIST_ACTION_BUTTON_MIN_HEIGHT,
            }}
          >
            {buttonText}
          </Button>
        </Box>
      </StyledTooltip>
      <PurchaseWhitelistModal
        key="purchase-whitelist-modal"
        whitelist={whitelist}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    </>
  );
};
