import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { Props } from './kongXDetails.types';
import { Metadata, TraitDetails } from '../../../components/metadata';
import { Currency, KongXMetadataTraits } from '../../../models/kongzApiTypes';
import { kongXImagePath } from '../../../helpers/kongx/kongXUtils';
import { useNavigate } from 'react-router-dom';
import { KONG_ARCHIVE_ROUTE } from '../../../constants/routes';
import { KONG_ACTION_BUTTON_MIN_HEIGHT } from '../../../constants/components';

export const KongXDetails: React.FunctionComponent<Props> = ({ kongX }) => {
  const traitDetailsList: TraitDetails[] = [];
  const kongXTraits: KongXMetadataTraits = kongX.metadata;
  const balance = kongX.balances !== undefined ? kongX.balances[Currency.EXONITE] : undefined;
  const exoniteAmount = balance === undefined ? 0 : balance.amount;

  Object.entries(kongXTraits).forEach(([key, trait]) => {
    if (trait !== null && trait.appearanceCount !== undefined) {
      const splitWordsKey = key.replace(/([A-Z])/g, ' $1');
      const type = splitWordsKey.charAt(0).toUpperCase() + splitWordsKey.slice(1);

      traitDetailsList.push({ type: type, trait: trait });
    }
  });

  const areButtonsEnabled = () => kongX !== undefined && kongX.rarity > 0;

  const button = (text: string, url: string) => {
    return (
      <Button
        variant="outlined"
        disabled={!areButtonsEnabled()}
        download={url}
        href={url}
        target="_blank"
        sx={{ minHeight: KONG_ACTION_BUTTON_MIN_HEIGHT, minWidth: '100%' }}
      >
        {text}
      </Button>
    );
  };

  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(KONG_ARCHIVE_ROUTE);
    }
  };

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} sm={5}>
        <Box>
          <img
            alt={`kongx-id-${kongX.tokenId}`}
            src={kongXImagePath(kongX.metadata.imageUrl)}
            width="100%"
            height="auto"
            style={{ minWidth: '250px', maxWidth: '640px' }}
          />
        </Box>
        <Box>
          <Button onClick={navigateBack} sx={{ p: 0, pt: 1 }}>{`<< go Back`}</Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Metadata
              name={kongX.metadata.name}
              rarity={kongX.rarity}
              exoniteAmount={exoniteAmount}
              traitDetailsList={traitDetailsList}
              items={[
                { key: 'Fuser 1', value: kongX.firstFuserData.name },
                { key: 'Fuser 2', value: kongX.secondFuserData.name },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {button('View ON IMX', kongX.url)}
              </Grid>
              <Grid item xs={6}>
                {button('Export PFP', kongX.metadata.pfpImageUrl)}
              </Grid>
              <Grid item xs={6}>
                {button('Export Full', kongX.metadata.imageUrl)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
