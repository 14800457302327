import Web3 from 'web3';
import { ENABLE_TEST_NETWORKS, MAINNET_NETWORK_ID } from '../constants/login';

export class Web3Helper {
  public readonly client: Web3;

  constructor() {
    if (!Web3.givenProvider) {
      throw Error('Could not find any Ethereum wallet. Install wallet extension to your browser to be able to login');
    }

    this.client = new Web3(Web3.givenProvider);
  }

  public async checkNetwork() {
    const networkId = await this.client.eth.net.getId();
    if (!ENABLE_TEST_NETWORKS && networkId !== MAINNET_NETWORK_ID) {
      throw Error('Current network is a test network. Please switch your wallet to mainnet and reload the page');
    }
  }
}

export const createWeb3HelperCheckingNetwork = async () => {
  const helper = new Web3Helper();
  await helper.checkNetwork();
  return helper;
};
