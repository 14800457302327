import { fromTo } from '../helpers/transformers';

export const KONGZ_CONTRACT_ADDRESS = `${process.env.REACT_APP_KONGZ_CONTRACT_ADDRESS}`;
export const KONGZ_LEGENDS_CONTRACT_ADDRESS = `${process.env.REACT_APP_KONGZ_LEGENDS_CONTRACT_ADDRESS}`;
export const KONGX_CONTRACT_ADDRESS = `${process.env.REACT_APP_KONGX_CONTRACT_ADDRESS}`;

export enum KongType {
  KONGZ = 'KONGZ',
  KONGZ_LEGEND = 'LEGEND',
  KONGX = 'KONGX',
}

export enum FuserType {
  KONGZ = KongType.KONGZ,
  KONGZ_LEGEND = KongType.KONGZ_LEGEND,
}

export const KONGZ_TYPE_CONTRACT: fromTo<KongType, string> = {
  [KongType.KONGZ]: KONGZ_CONTRACT_ADDRESS,
  [KongType.KONGZ_LEGEND]: KONGZ_LEGENDS_CONTRACT_ADDRESS,
  [KongType.KONGX]: KONGX_CONTRACT_ADDRESS,
};
