import { Box, Card, CardContent, CardMedia, Link, Typography } from '@mui/material';
import React from 'react';
import { KongDetailsType, Props } from './kongCard.types';
import { kongXImagePath } from '../../helpers/kongx/kongXUtils';
import { CARD_ACTION_DEFAULT_ELEMENT_WIDTH as CARD_WIDTH} from '../../constants/components';

export const KongCard: React.FunctionComponent<Props> = ({
  kong,
  components,
  borderColor,
  details,
  link,
  scaled = false,
}) => {
  type FontSize = 'inherit' | '0.6rem' | '0.7rem' | '0.8rem';
  type Config = { nameSize: FontSize; rankSize: FontSize; otherSize: FontSize; width: number; unknown: string };

  const config: Config = scaled
    ? { nameSize: '0.8rem', rankSize: '0.7rem', otherSize: '0.6rem', width: 150, unknown: '??' }
    : { nameSize: 'inherit', rankSize: 'inherit', otherSize: 'inherit', width: CARD_WIDTH, unknown: 'unknown' };

  const title = scaled ? `#${kong.tokenId}` : kong.name;

  const typography = (text: string, variant: KongDetailsType, fontSize: FontSize) => {
    const color = variant === 'h5' ? 'text.secondary' : undefined;

    return (
      <Typography key={kong.name + '-' + text} variant={variant} color={color} fontSize={fontSize} sx={{ pb: 0.5 }}>
        {text}
      </Typography>
    );
  };

  const rarity = kong.rarity > 0 ? kong.rarity : config.unknown;

  const cardMedia = () => {
    const simpleCardMedia = (
      <CardMedia
        key={kong.name + '-image'}
        component="img"
        alt={kong.name}
        image={kong.imageUrl ? kongXImagePath(kong.imageUrl) : undefined}
        sx={{ padding: 0.5, border: `1px solid ${borderColor}` }}
      />
    );

    return link !== undefined ? <Link href={link}>{simpleCardMedia}</Link> : simpleCardMedia;
  };

  const detailsBox = details.length > 0 ?
    (
      <Box sx={{ pt: 1 }}>
        {details.map((detailsRow) => typography(detailsRow.text, detailsRow.type, config.otherSize))}
      </Box>
    ) : (<Box></Box>);

  return (
    <Card sx={{ width: config.width }}>
      {cardMedia()}
      <CardContent sx={{ border: `1px solid ${borderColor}` }}>
        <Box>{[typography(title, 'h5', config.nameSize), typography(`Rank > ${rarity}`, 'h6', config.rankSize)]}</Box>
        {detailsBox}
        <Box sx={{ pt: 1 }}>{components !== undefined && components}</Box>
      </CardContent>
    </Card>
  );
};
