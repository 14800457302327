import React from 'react';
import { Props } from './ownedKongZCard.types';
import { KongZCard } from '../../../components/kongCard/kongZCard';
import { ProjectColors } from '../../../themes/mainTheme';
import { KongButtonCardActions } from '../../../components/kongButtonCardActions';

export const OwnedKongZCard: React.FunctionComponent<Props> = ({
  kong,
  onTransferSuccess,
  onTransferFailure,
  onEnableMiningSuccess,
  onEnableMiningFailure,
  onCurrencyClaimSuccess,
  onCurrencyClaimFailure,
  onCurrencyLoadSuccess,
  onCurrencyLoadFailure,
}) => {
  return (
    <KongZCard
      key={kong.name + "-z-card"}
      kong={kong}
      borderColor={kong.isMiningCurrency ? ProjectColors.HYPER : ProjectColors.WHITE}
      components={[
        <KongButtonCardActions
          key={kong.name + "-x-button-card-actions"}
          kong={kong}
          onTransferSuccess={onTransferSuccess}
          onTransferFailure={onTransferFailure}
          onEnableMiningSuccess={onEnableMiningSuccess}
          onEnableMiningFailure={onEnableMiningFailure}
          onCurrencyClaimSuccess={onCurrencyClaimSuccess}
          onCurrencyClaimFailure={onCurrencyClaimFailure}
          onCurrencyLoadSuccess={onCurrencyLoadSuccess}
          onCurrencyLoadFailure={onCurrencyLoadFailure}
        />
      ]}
    />
  );
};
