import {
  Box,
  Link,
  Paper, SxProps,
  Table,
  TableBody,
  TableCell as TableCellMui,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow, Theme,
} from '@mui/material';
import React from 'react';
import {
  allPageSizes,
  Column,
  ColumnMobile,
  DISCORD_ID_LOOKUP_URL,
  PageSize,
  Props
} from './whitelistPurchaseTable.types';
import { ProjectColors } from '../../themes/mainTheme';
import { CURRENCY_COLOR } from '../../models/kongzApiTypes';
import { TableCell } from '../tableCell';
import { AppContext } from '../../global/context/appContext';
import { HelpOutline } from '@mui/icons-material';

export const WhitelistPurchaseTable: React.FunctionComponent<Props> = ({ rows }) => {
  const { isMobile } = React.useContext(AppContext);

  const columnNames = isMobile ? Object.values(ColumnMobile) : Object.values(Column);

  const [rowsPerPage, setRowsPerPage] = React.useState<PageSize>(25);
  const [pageNumber, setPageNumber] = React.useState(0);

  const headerCell = (value: any) => {
    if (String(value) === Column.DISCORD_ID) {
      value = (
        <Box component="div" sx={{ align: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          {value}
          <Link
            href={DISCORD_ID_LOOKUP_URL}
            target={'_blank'}
            sx={{ pl: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <HelpOutline />
          </Link>
        </Box>
      )
    }

    return (
      <TableCellMui
        key={value as string}
        align="center"
        sx={{ border: `2px solid ${ProjectColors.SECONDARY}`, color: `${ProjectColors.SECONDARY}` }}
      >
        {value}
      </TableCellMui>
    );
  };

  const currentPageRows = () => {
    const from = pageNumber === 0 ? 0 : pageNumber * rowsPerPage;
    const to = from + rowsPerPage > rows.length ? rows.length : from + rowsPerPage;

    return rows.slice(from, to);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10) as PageSize);
    setPageNumber(0);
  };

  const tableStyles: SxProps<Theme> = { minWidth: '90%' };
  const tableRowStyles: SxProps<Theme> = { border: 0, height: 100,  maxHeight: 100 };

  if (isMobile) {
    tableStyles.mt = 3;
    tableRowStyles.wordBreak = 'break-word';
    tableRowStyles.whiteSpace = 'normal';
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={tableStyles} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columnNames.map((column) => {
              return headerCell(column);
            })}
          </TableRow>
        </TableHead>
        {rows.length > 0 && (
          <TableBody>
            {currentPageRows().map((row) => (
              <TableRow key={row.id + '-' + row.user} sx={tableRowStyles}>
                { isMobile ? <></> : <TableCell value={row.user} paddingX={1} textTransform={'lowercase'} /> }
                { isMobile ? <></> : <TableCell value={row.discordId} paddingX={1} /> }
                <TableCell value={row.purchasedFor} paddingX={1} textTransform={'lowercase'} />
                <TableCell value={row.purchasedAt.toDateString()} paddingX={1} />
                <TableCell value={row.name} paddingX={1} />
                {
                  isMobile ? <></> :
                    <TableCell
                      value={row.spotsLeft}
                      color={row.spotsLeft > 0 ? CURRENCY_COLOR[row.currency] : ProjectColors.GREY}
                    />
                }
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter>
          {rows.length > 0 ? (
            <TableRow>
              <TableCellMui colSpan={columnNames.length}>
                <TablePagination
                  rowsPerPageOptions={allPageSizes}
                  component="div"
                  count={rows.length}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={isMobile ? '' : 'Rows per page:'}
                />
              </TableCellMui>
            </TableRow>
          ) : (
            <TableRow key="loading-all-whitelist-purchases" sx={{ border: 0 }}>
              <TableCellMui align="center" colSpan={columnNames.length}>
                No whitelist purchases found
              </TableCellMui>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
