import React from 'react';
import { Props } from './metadata.types';
import { Box, Typography } from '@mui/material';
import { TraitDetails } from '../traitDetails';
import { ProjectColors } from '../../themes/mainTheme';

export const Metadata: React.FunctionComponent<Props> = ({ traitDetailsList, items, name, rarity, exoniteAmount }) => {
  return (
    <Box>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h5" color="text.secondary">{`Name > ${name}`}</Typography>
        <Typography variant="h6" color="text.primary">{`Rank > ${rarity ? rarity : 'Unknown'}`}</Typography>
        <Typography variant="h6" color={ProjectColors.EXONITE}>{`Exonite > ${exoniteAmount}`}</Typography>
      </Box>
      {items.length > 0 && (
        <Box sx={{ pb: 2 }}>
          {items.map(({ key, value }) => (
            <Typography key={key} variant="body2" color="text.primary">{`${key} > ${value}`}</Typography>
          ))}
        </Box>
      )}
      <Box>
        {traitDetailsList.map(({ type, trait }) => (
          <TraitDetails key={type} type={type} trait={trait} />
        ))}
      </Box>
    </Box>
  );
};
