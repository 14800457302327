import React from 'react';
import { Props } from './connectWalletButton.types';
import { Button } from '@mui/material';
import { createWeb3HelperCheckingNetwork } from '../../helpers/Web3Helper';
import { ProjectColors } from '../../themes/mainTheme';
import KongzSignedApiHelper from '../../helpers/apiImmKongz/KongzSignedApiHelper';
import { WalletAlert } from '../../global/app/walletAlert';

export const ConnectWalletButton: React.FunctionComponent<Props> = ({ isConnected, setWallet, isMobile }) => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  const connectWallet = async () => {
    try {
      const web3Helper = await createWeb3HelperCheckingNetwork();
      const web3 = web3Helper.client;
      const accounts = await web3.eth.requestAccounts();
      const account = accounts[0];
      await KongzSignedApiHelper.registerUser(account);

      setWallet(account);
    } catch (e: any) {
      setAlertMessage(e.message);
      setAlertOpen(true);
    }
  };

  const buttonSx = isMobile
    ? { minHeight: '32px', border: '1px solid', borderColor: ProjectColors.MAIN, m: 1 }
    : { minHeight: '64px', border: '1px solid', borderColor: ProjectColors.MAIN };

  const connectButton = () => {
    return (
      <Button variant="contained" color="secondary" onClick={connectWallet} sx={buttonSx}>
        Connect Wallet
      </Button>
    );
  };

  const disconnectWallet = () => {
    try {
      setWallet(null);
    } catch (e: any) {
      setAlertMessage(e.message);
      setAlertOpen(true);
    }
  };

  const disconnectButton = () => {
    return (
      <Button variant="contained" onClick={disconnectWallet} sx={buttonSx}>
        Disconnect Wallet
      </Button>
    );
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  return (
    <div>
      <WalletAlert text={alertMessage} isOpen={alertOpen} onClosed={closeAlert} />
      {isConnected ? disconnectButton() : connectButton()}
    </div>
  );
};
