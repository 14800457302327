import { KongXTableRow } from '../views/kongArchive/allKongX/kongXTable';
import { KongXFiltersData } from '../views/kongArchive/allKongX/kongXFilters';
import { KongDataTableRow } from '../components/kongDataTable';
import { KongDataFilters } from '../components/kongDataTableFilters';
import { FuserType } from '../constants/kongs';
import { WhitelistPurchaseFilters } from '../components/whitelistPurchaseTableFilters';
import { WhitelistPurchaseData } from '../components/whitelistCard';

export type TokenIdFilterable = { id: number };
export type TokenIdFilters = { tokenId?: number };

export type FuserToFilter = { type: FuserType; tokenId: number };
export type FuserFilterable = { firstFuser: FuserToFilter; secondFuser: FuserToFilter };
export type FuserFilters = { fuserType?: string; fuserId?: number };

export type EarringsFilterable = { earring: string };
export type EarringsFilters = { earring?: string };

export type ExoniteFilterable = { exonite: number };
export type ExoniteFilters = { exoniteMore?: number; exoniteLess?: number };

export type FusionsFilterable = { fusions: number };
export type FusionsFilters = { fusions?: number };

export type HyperFilterable = { hyper: number };
export type HyperFilters = { hyperMore?: number; hyperLess?: number };

export type RarityFilterable = { rarity: number };
export type RarityFilters = { rarityMore?: number; rarityLess?: number };

export type NameFilterable = { name: string };
export type NameFilters = { name?: string }

export type UserFilterable = { user: string };
export type UserFilters = { user?: string }

export type DiscordIdFilterable = { discordId: string };
export type DiscordIdFilters = { discordId?: string }

export type PurchasedForFilterable = { purchasedFor: string };
export type PurchasedForFilters = { purchasedFor?: string }

export class FiltersHelper {
  private static instance: FiltersHelper;

  public static getInstance(): FiltersHelper {
    if (!FiltersHelper.instance) {
      FiltersHelper.instance = new FiltersHelper();
    }

    return FiltersHelper.instance;
  }

  public filterKongXRows = (rows: KongXTableRow[], filters: KongXFiltersData): KongXTableRow[] => {
    return rows
      .filter(this.filterByEqualTokenId(filters))
      .filter(this.filterByFuserId(filters))
      .filter(this.filterByFuserType(filters))
      .filter(this.filterByExonite(filters));
  };

  public filterKongZRows = (rows: KongDataTableRow[], filters: KongDataFilters): KongDataTableRow[] => {
    return rows
      .filter(this.filterByEqualTokenId(filters))
      .filter(this.filterByEarring(filters))
      .filter(this.filterByFusions(filters))
      .filter(this.filterByRarity(filters))
      .filter(this.filterByHyper(filters));
  };

  public filterWhitelistPurchaseRows = (
    rows: WhitelistPurchaseData[],
    filters: WhitelistPurchaseFilters
  ): WhitelistPurchaseData[] => {
    return rows
      .filter(this.filterByName(filters))
      .filter(this.filterByUser(filters))
      .filter(this.filterByDiscordId(filters))
      .filter(this.filterByPurchasedFor(filters))
  }

  private filterByEqualTokenId =
    <T extends TokenIdFilterable>(filters: TokenIdFilters) =>
    (row: T) => {
      return filters.tokenId !== undefined && filters.tokenId > 0 ? row.id === +filters.tokenId : true;
    };

  private filterByFuserType =
    <T extends FuserFilterable>(filters: FuserFilters) =>
    (row: T) => {
      return filters.fuserType !== undefined
        ? [row.firstFuser, row.secondFuser].map((fuser) => fuser.type.toString()).includes(filters.fuserType)
        : true;
    };

  private filterByFuserId =
    <T extends FuserFilterable>(filters: FuserFilters) =>
    (row: T) => {
      if (filters.fuserId === undefined || +filters.fuserId <= 0) {
        return true;
      }

      const searchedId = +filters.fuserId;
      return filters.fuserType !== undefined
        ? (row.firstFuser.tokenId === searchedId && row.firstFuser.type.toString() === filters.fuserType) ||
            (row.secondFuser.tokenId === searchedId && row.secondFuser.type.toString() === filters.fuserType)
        : [row.firstFuser.tokenId, row.secondFuser.tokenId].includes(+filters.fuserId);
    };

  private filterByEarring =
    <T extends EarringsFilterable>(filters: EarringsFilters) =>
    (row: T) => {
      if (filters.earring !== undefined && filters.earring !== '') {
        return row.earring.toLowerCase() === filters.earring.toLowerCase();
      }

      return true;
    };

  private filterByFusions =
    <T extends FusionsFilterable>(filters: FusionsFilters) =>
    (row: T) => {
      return filters.fusions !== undefined && filters.fusions !== null ? row.fusions === +filters.fusions : true;
    };

  private filterByHyper =
    <T extends HyperFilterable>(filters: HyperFilters) =>
    (row: T) => {
      return (
        (filters.hyperLess !== undefined && filters.hyperLess > 0 ? row.hyper < filters.hyperLess : true) &&
        (filters.hyperMore !== undefined && filters.hyperMore > 0 ? row.hyper > filters.hyperMore : true)
      );
    };

  private filterByExonite =
    <T extends ExoniteFilterable>(filters: ExoniteFilters) =>
      (row: T) => {
        return (
          (filters.exoniteLess !== undefined && filters.exoniteLess > 0 ? row.exonite < filters.exoniteLess : true) &&
          (filters.exoniteMore !== undefined && filters.exoniteMore > 0 ? row.exonite > filters.exoniteMore : true)
        );
      };

  private filterByRarity =
    <T extends RarityFilterable>(filters: RarityFilters) =>
    (row: T) => {
      return (
        (filters.rarityLess !== undefined && filters.rarityLess > 0 ? row.rarity < filters.rarityLess : true) &&
        (filters.rarityMore !== undefined && filters.rarityMore > 0 ? row.rarity > filters.rarityMore : true)
      );
    };

  private filterByName =
    <T extends NameFilterable>(filters: NameFilters) =>
      (row: T) => {
        return (
          (filters.name !== undefined && filters.name.length > 0
            ? row.name.toLowerCase().includes(filters.name.toLowerCase())
            : true
          )
        );
      };

  private filterByUser =
    <T extends UserFilterable>(filters: UserFilters) =>
      (row: T) => {
        return (
          (filters.user !== undefined && filters.user.length > 0
            ? row.user.toLowerCase().includes(filters.user.toLowerCase())
            : true
          )
        );
      };

  private filterByDiscordId =
    <T extends DiscordIdFilterable>(filters: DiscordIdFilters) =>
      (row: T) => {
        return (
          (filters.discordId !== undefined && filters.discordId.length > 0
              ? row.discordId.toLowerCase().includes(filters.discordId.toLowerCase())
              : true
          )
        );
      };

  private filterByPurchasedFor =
    <T extends PurchasedForFilterable>(filters: PurchasedForFilters) =>
      (row: T) => {
        return (
          (filters.purchasedFor !== undefined && filters.purchasedFor.length > 0
              ? row.purchasedFor.toLowerCase().includes(filters.purchasedFor.toLowerCase())
              : true
          )
        );
      };
}

export default FiltersHelper.getInstance();
