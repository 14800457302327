export const DISCORD_SERVER_URL = `${process.env.REACT_APP_DISCORD_SERVER_URL}`;
export const DISCORD_AUTHORIZE_URL = `${process.env.REACT_APP_DISCORD_AUTHORIZE_URL}`;
export const DISCORD_OAUTH_TOKEN_URL = `${process.env.REACT_APP_DISCORD_OAUTH_TOKEN_URL}`;
export const DISCORD_USER_DATA_URL = `${process.env.REACT_APP_DISCORD_USER_DATA_URL}`;
export const DISCORD_APP_CLIENT_ID = `${process.env.REACT_APP_DISCORD_APP_CLIENT_ID}`;
export const DISCORD_APP_CLIENT_SECRET = `${process.env.REACT_APP_DISCORD_APP_CLIENT_SECRET}`;
export const DISCORD_APP_REDIRECT_URL= `${process.env.REACT_APP_DISCORD_APP_REDIRECT_URL}`;

export enum DiscordButton {
  DISCORD = 'Discord',
  OPEN = 'Open',
  CONNECT = 'Connect',
  CONNECT_NEW = 'Connect new',
}

export enum ConnectionState {
  CONNECTING = 'Connecting to Discord!',
  FAILED_TO_CONNECT = 'Failed to connect. Redirecting to the previous page',
  CONNECTED = 'Success! Redirecting to the previous page',
}
