import { CONNECTED_WALLET_STORAGE_KEY } from '../constants/login';
import StoreTmpDataHelper from './StoreTmpDataHelper';

export class WalletHelper {
  public static maskWallet(wallet: string): string {
    if (!wallet || wallet.length === 0) {
      return '';
    }

    return `${wallet.substring(0, 5)}...${wallet.substring(wallet.length - 4, wallet.length)}`;
  }

  public static storeWallet(walletToSave: string | null) {
    StoreTmpDataHelper.store(CONNECTED_WALLET_STORAGE_KEY, walletToSave)
  }

  public static getStoredWallet(): string | null {
    return StoreTmpDataHelper.getStored(CONNECTED_WALLET_STORAGE_KEY);
  }
}
