import React from 'react';
import { WhitelistPurchaseFilters, Props } from './whitelistPurchaseTableFilters.types';
import { Grid } from '@mui/material';
import { InputField } from '../textField';
import { AccordionWrapper } from '../accordionWrapper';

export const WhitelistPurchaseTableFilters: React.FunctionComponent<Props> = ({ filters, setFilters }) => {
  const handleChange = (prop: keyof WhitelistPurchaseFilters) => (filter: string) => {
    setFilters({ ...filters, [prop]: filter === '' ? undefined : filter });
  };

  const item = (element: React.ReactNode, sm: number) => (
    <Grid item sm={sm} xs={12}>
      {element}
    </Grid>
  );

  return (
    <AccordionWrapper summary="Filters">
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {item(<InputField label="Whitelist Name" onChange={handleChange('name')} />, 6)}
        {item(<InputField label="Purchased for" onChange={handleChange('purchasedFor')} />, 6)}
        {item(<InputField label="Purchased by" onChange={handleChange('user')} />, 6)}
        {item(<InputField label="Discord Id" onChange={handleChange('discordId')} />, 6)}
      </Grid>
    </AccordionWrapper>
  );
};
