import React from 'react';
import { Props } from './allWhitelists.types';
import { UserRole } from '../../../models/kongzApiTypes';
import { AppContext } from '../../../global/context/appContext';
import { Grid } from '@mui/material';
import { WhitelistCard, WhitelistCardData } from '../../../components/whitelistCard';
import { AddWhitelistButton } from '../../../components/addWhitelistButton';

export const AllWhitelists: React.FunctionComponent<Props> = ({
  allWhitelists,
  onWhitelistAddSuccess,
  onWhitelistAddFailure,
  onWhitelistPurchaseSuccess,
  onWhitelistPurchaseFailure,
  onWhitelistEditSuccess,
  onWhitelistEditFailure,
  onWhitelistDeleteSuccess,
  onWhitelistDeleteFailure,
}) => {
  const { user } = React.useContext(AppContext);
  const [addWhitelistButton, setAddWhitelistButton] = React.useState<undefined | JSX.Element>(undefined);

  React.useEffect(() => {
    updateAddWhitelistButton();
  }, [user]);

  const updateAddWhitelistButton = () => {
    if (user === undefined || !user.roles.includes(UserRole.IS_WHITELIST_MANAGER)) {
      setAddWhitelistButton(undefined);
      return;
    }

    setAddWhitelistButton(
      <AddWhitelistButton
        key={'add-whitelist-button'}
        onSuccess={onWhitelistAddSuccess}
        onFailure={onWhitelistAddFailure}
      />
    );
  };

  const [active, inactive] = allWhitelists.reduce(
    (result: WhitelistCardData[][], element) => {
      result[element.availableSpots > 0 ? 0 : 1].push(element);
      return result;
    },
    [[], []]
  );

  const sortedWhitelists = active.concat(inactive);

  return (
    <div>
      {addWhitelistButton}
      <Grid container spacing={3}>
        {sortedWhitelists.map((card, index) => (
          <Grid item xs="auto" key={card.name + index}>
            <WhitelistCard
              key={card.name}
              whitelist={card}
              isWhitelistAvailable={card.availableSpots > 0}
              onPurchaseSuccess={onWhitelistPurchaseSuccess}
              onPurchaseFailure={onWhitelistPurchaseFailure}
              onEditSuccess={onWhitelistEditSuccess}
              onEditFailure={onWhitelistEditFailure}
              onDeleteSuccess={onWhitelistDeleteSuccess}
              onDeleteFailure={onWhitelistDeleteFailure}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
