import React from 'react';
import { Props } from './traitDetails.types';
import { Box, LinearProgress, Typography } from '@mui/material';
import { roundToDecimal } from '../../helpers/utils';

export const TraitDetails: React.FunctionComponent<Props> = ({ type, trait }) => {
  const roundedPercentage = roundToDecimal(trait.appearancePercentage);

  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <Typography variant="body2" color="text.primary">{`${type} > ${trait.name}`}</Typography>
      <Box>
        <Box sx={{ width: '100%', pt: 0.5, pb: 0.5 }}>
          <LinearProgress
            variant="determinate"
            value={roundedPercentage}
            color={'primary'}
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>
      </Box>
      <Typography variant="body2" color="text.primary" sx={{ fontSize: '0.6rem' }}>
        {`${roundedPercentage}% (${trait.appearanceCount}) have this trait`}
      </Typography>
    </Box>
  );
};
