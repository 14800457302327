import {
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell as TableCellMui,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React from 'react';
import { Column, Props } from './kongXTable.types';
import { ProjectColors } from '../../../../themes/mainTheme';
import { kongXImagePath } from '../../../../helpers/kongx/kongXUtils';
import { LinkToKong } from '../../../../components/linkToKong';
import { KONG_ARCHIVE_KONGX_ROUTE } from '../../../../constants/routes';
import { allPageSizes, PageSize } from '../../../../components/kongDataTable';
import { Exonite } from '../../../../components/exonite';
import { TableCell } from '../../../../components/tableCell';

export const KongXTable: React.FunctionComponent<Props> = ({ rows }) => {
  const columnNames = Object.values(Column);

  const [rowsPerPage, setRowsPerPage] = React.useState<PageSize>(25);
  const [pageNumber, setPageNumber] = React.useState(0);

  const headerCell = (value: any, color: string | null = null) => {
    const textColor = color ? color : ProjectColors.SECONDARY;

    return (
      <TableCellMui
        key={value as string}
        align="center"
        colSpan={value.includes('Fuser') ? 2 : 1}
        sx={{ border: `2px solid ${ProjectColors.SECONDARY}`, color: `${textColor}` }}
      >
        {value}
      </TableCellMui>
    );
  };

  const card = (imageUrl: string, alt: string) => {
    return <img alt={alt} src={imageUrl} width="100" height="100" />;
  };

  const kongXCard = (id: number, imageUrl: string, alt: string) => {
    return <Link href={KONG_ARCHIVE_KONGX_ROUTE(id.toString())}>{card(imageUrl, alt)}</Link>;
  };

  const currentPageRows = () => {
    const from = pageNumber === 0 ? 0 : pageNumber * rowsPerPage;
    const to = from + rowsPerPage > rows.length ? rows.length : from + rowsPerPage;

    return rows.slice(from, to);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10) as PageSize);
    setPageNumber(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '90%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columnNames.map((column) => {
              const color = column === Column.EXONITE_ORES ? ProjectColors.EXONITE : null;
              return headerCell(column, color);
            })}
          </TableRow>
        </TableHead>
        {rows.length > 0 && (
          <TableBody>
            {currentPageRows().map((row) => (
              <TableRow key={row.id} sx={{ border: 0 }}>
                <TableCell
                  value={kongXCard(row.id, kongXImagePath(row.imagePfpUrl), 'kong-x')}
                  width={100}
                  wrapInTypography={false}
                />
                <TableCell value={row.id} />
                <TableCell value={row.rarity === 0 ? 'Unknown' : row.rarity} />
                <TableCell value={row.firstFuser.name} />
                <TableCell
                  value={card(row.firstFuser.imageUrl, 'fuser-1')}
                  width={100}
                  wrapInTypography={false}
                />
                <TableCell
                  value={card(row.secondFuser.imageUrl, 'fuser-2')}
                  width={100}
                  wrapInTypography={false}
                />
                <TableCell value={row.secondFuser.name} />
                <TableCell value={<Exonite amount={row.exonite} hideName centered />} wrapInTypography={false} />
                <TableCell value={<LinkToKong url={row.url} id={row.id} />} />
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter>
          {rows.length > 0 ? (
            <TableRow>
              <TableCellMui colSpan={columnNames.length + 2}>
                <TablePagination
                  rowsPerPageOptions={allPageSizes}
                  component="div"
                  count={rows.length}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCellMui>
            </TableRow>
          ) : (
            <TableRow key="loading-all-kongx" sx={{ border: 0 }}>
              <TableCellMui align="center" colSpan={columnNames.length + 2}>
                <CircularProgress />
              </TableCellMui>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
