import { Props } from './topBar.types';
import React from 'react';
import { AppBar as MuiAppBar, Toolbar, IconButton, Tooltip, Box } from '@mui/material';
import { AccountBalanceWallet } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { ConnectWalletButton } from '../../../../components/connectWalletButton';
import { MENU_WIDTH } from '../../menu';
import { Menu as MenuIcon } from '@mui/icons-material';
import { WalletHelper } from '../../../../helpers/WalletHelper';
import { Hyper } from '../../../../components/hyper';
import { AppContext } from '../../../context/appContext';
import { Currency } from '../../../../models/kongzApiTypes';
import { ProjectColors } from '../../../../themes/mainTheme';
import { DiscordMenuResolver } from '../../../../components/discord';
import { Exonite } from '../../../../components/exonite';

export const TopBar: React.FunctionComponent<Props> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  wallet,
  saveWallet,
  isWalletAccessible,
}) => {
  const { user, isMobile } = React.useContext(AppContext);

  let hyperAmount = 0;
  let exoniteAmount = 0;

  if (user !== undefined && user.balances !== undefined) {
    const hyperBalance = user.balances[Currency.HYPER];
    hyperAmount = hyperBalance === undefined ? 0 : hyperBalance.amount;

    const exoniteBalance = user.balances[Currency.EXONITE];
    exoniteAmount = exoniteBalance === undefined ? 0 : exoniteBalance.amount;
  }

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${MENU_WIDTH}px)`,
      marginLeft: `${MENU_WIDTH}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return isMobile ? (
    <MuiAppBar position="fixed">
      <Toolbar disableGutters sx={{ pl: '24px', pr: '24px', border: `1px solid ${ProjectColors.WHITE}` }}>
        <IconButton color="inherit" aria-label="open drawer" onClick={() => setIsDrawerOpen(true)} edge="start">
          <MenuIcon />
        </IconButton>
        <Box sx={{ m: 'auto' }}>{user && <Hyper hideName amount={hyperAmount} />}</Box>
        <Box sx={{ m: 'auto' }}>{user && <Exonite hideName amount={exoniteAmount} />}</Box>
      </Toolbar>
    </MuiAppBar>
  ) : (
    <AppBar position="fixed" open={isDrawerOpen}>
      <Toolbar disableGutters sx={{ paddingLeft: '24px', border: `1px solid ${ProjectColors.WHITE}` }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setIsDrawerOpen(true)}
          edge="start"
          sx={{ display: 'flex', mr: '36px', ...(isDrawerOpen && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ m: 'auto' }}>{user && <Hyper amount={hyperAmount} tooltip="Your personal Hyper wallet"/>}</Box>
        <Box sx={{ m: 'auto' }}>{user && <Exonite amount={exoniteAmount} tooltip="Your personal Exonite wallet"/>}</Box>
        <Box sx={{ display: 'flex', mr: 0 }}>
          {isWalletAccessible &&
              <DiscordMenuResolver isConnected={user !== undefined && user.discordUser !== null} wallet={wallet}/>}
        </Box>
        <Box sx={{ display: 'flex', mr: 0 }}>
          {wallet && (
            <Tooltip title={WalletHelper.maskWallet(wallet)} placement="left" arrow sx={{ m: 'auto', mr: 2, ml: 2 }}>
              <AccountBalanceWallet sx={{ width: '2em', height: '1.6em', my: 'auto', mr: 2 }} />
            </Tooltip>
          )}
          {isWalletAccessible && (
            <ConnectWalletButton isMobile={false} setWallet={saveWallet} isConnected={wallet !== null} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
