import { ThemeOptions, createTheme } from '@mui/material/styles';

export enum ProjectColors {
  MAIN = '#FF00E1',
  SECONDARY = '#6DEAD6',
  BLACK = '#000000',
  WHITE = '#FFEBEE',
  HYPER = '#FFF400',
  EXONITE = '#7CCC06',
  GREY = '#868282',
  RED = '#FF0000',
}

export const mainTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: ProjectColors.MAIN,
      contrastText: ProjectColors.BLACK,
      dark: ProjectColors.WHITE,
      light: ProjectColors.WHITE,
    },
    secondary: {
      main: ProjectColors.SECONDARY,
      contrastText: ProjectColors.BLACK,
      dark: ProjectColors.WHITE,
      light: ProjectColors.WHITE,
    },
    background: {
      default: ProjectColors.BLACK,
      paper: ProjectColors.BLACK,
    },
    text: {
      primary: ProjectColors.WHITE,
      secondary: ProjectColors.MAIN,
      disabled: ProjectColors.GREY,
    },
    divider: ProjectColors.MAIN,
  },
  typography: {
    fontFamily: 'dogicapixel',
    button: {
      textTransform: 'uppercase',
      fontSize: '0.7rem',
      fontFamily: 'dogicapixelbold',
    },
    body1: {
      textTransform: 'uppercase',
      fontSize: '0.8rem',
    },
    body2: {
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      fontFamily: 'dogicapixelbold',
    },
    h5: {
      textTransform: 'uppercase',
      fontFamily: 'dogicapixelbold',
      fontSize: '1.1rem',
    },
    h6: {
      textTransform: 'uppercase',
      fontFamily: 'dogicapixelbold',
      fontSize: '1rem',
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          '&:hover': {
            borderColor: ProjectColors.WHITE,
            color: ProjectColors.WHITE,
          },
        },
      },
    },
  },
});
