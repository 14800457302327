import React from 'react';
import { Props } from './allLegends.types';
import { KongDataFilters, KongDataTableFilters } from '../../../components/kongDataTableFilters';
import { KongDataTable, KongDataTableRow } from '../../../components/kongDataTable';
import { kongDataToTableRow, kongZRowToMobileCard } from '../../../helpers/transformers';
import KongZApiHelper from '../../../helpers/apiImmKongz/KongZApiHelper';
import { Currency } from '../../../models/kongzApiTypes';
import { KongType } from '../../../constants/kongs';
import { FusersCollectionInfo } from '../fusersCollectionInfo';
import FiltersHelper from '../../../helpers/FiltersHelper';
import { AppContext } from '../../../global/context/appContext';
import { MobileKongs } from '../../../components/mobileKongs';

export const AllLegends: React.FunctionComponent<Props> = ({ legendDataList }) => {
  const { isMobile } = React.useContext(AppContext);
  const [filters, setFilters] = React.useState<KongDataFilters>({});
  const [miningCount, setMiningCount] = React.useState(0);
  const [tradeVolume, setTradeVolume] = React.useState(0);
  const [rows, setRows] = React.useState<KongDataTableRow[]>([]);

  React.useEffect(() => {
    updateMiningCount();

    //the endpoint does not work. Commented out for now
    //updateTradeVolume();
  }, []);

  React.useEffect(() => {
    const allRows = legendDataList.map((data) => kongDataToTableRow(data));
    const filteredRows = FiltersHelper.filterKongZRows(allRows, filters);
    setRows(filteredRows);
  }, [filters, legendDataList]);

  const updateMiningCount = async () => {
    setMiningCount(await KongZApiHelper.miningCount(KongType.KONGZ_LEGEND, Currency.HYPER));
  };

  const updateTradeVolume = async () => {
    setTradeVolume(await KongZApiHelper.tradeVolume(KongType.KONGZ_LEGEND));
  };

  return (
    <div style={{ height: 500, width: '90%', margin: 'auto' }}>
      <FusersCollectionInfo
        tokensMinted={legendDataList.length}
        totalVolume={tradeVolume}
        currentlyMining={miningCount}
      />
      <KongDataTableFilters filters={filters} setFilters={setFilters} />
      {isMobile ? <MobileKongs rows={rows} transformToCard={kongZRowToMobileCard} /> : <KongDataTable rows={rows} />}
    </div>
  );
};
