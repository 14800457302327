import { ReactElement } from 'react';
import { Currency, UserData } from '../../models/kongzApiTypes';
import { KongType } from '../../constants/kongs';
import { OwnedMiningKongCard } from '../kongCard';

export type Props = {
  type: CurrencyTransferButtonType;
  kong: OwnedMiningKongCard;
  user: UserData | undefined;
  buttonText: ReactElement;
  buttonsInRow?: number;
  texts: CurrencyButtonTexts;
  onSuccess: (currency: Currency, amount: number, wallet: string, type: KongType, id: number) => void;
  onFailure: (currency: Currency, amount: number, wallet: string, type: KongType, id: number, reason: string) => void;
};

export enum CurrencyTransferButtonType {
  USER_TO_KONG = 'user_to_kong',
  KONG_TO_USER = 'kong_to_user',
}

export type CurrencyButtonTexts = {
  tooltip: string;
  modalTitle: string;
  modalBody: string;
};
