import React from 'react';
import { Props } from './kongXCollectionInfo.types';
import { CollectionInfo } from '../../../components/collectionInfo';

export const KongXCollectionInfo: React.FunctionComponent<Props> = ({ fusionCount, tokensMinted }) => {
  const fusionCountDetail = { label: 'Total fuses', value: fusionCount };
  const tokensMintedDetail = { label: 'Tokens minted', value: tokensMinted };
  const totalVolumeDetail = { label: 'Total volume', value: `N/A` };

  return (
    <CollectionInfo
      details={{
        firstDetail: fusionCountDetail,
        secondDetail: tokensMintedDetail,
        thirdDetail: totalVolumeDetail,
      }}
    />
  );
};
