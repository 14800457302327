import React from 'react';
import { Props } from './fusers.types';
import { Box, Button, CardActions, Grid } from '@mui/material';
import { KongZCard, KongZCardData } from '../../../components/kongCard/kongZCard';
import { ProjectColors } from '../../../themes/mainTheme';
import { contains } from '../../../helpers/arrayFunctions';
import { KONG_ACTION_BUTTON_MIN_HEIGHT } from '../../../constants/components';

export const Fusers: React.FunctionComponent<Props> = ({ onSelect, selectedKongs, kongs }) => {
  const button = (variant: 'outlined' | 'contained', text: string, kong: KongZCardData) => (
    <Button
      size="small"
      variant={variant}
      onClick={() => onSelect(kong)}
      sx={{
        minWidth: '100%',
        minHeight: KONG_ACTION_BUTTON_MIN_HEIGHT,
        pb: 0,
      }}
    >
      {text}
    </Button>
  );

  const resolveButton = (kong: KongZCardData) =>
    contains(selectedKongs, kong) ? button('outlined', 'Unselect', kong) : button('contained', 'Select', kong);

  return (
    <Box>
      <Grid container spacing={3} maxWidth={1320} wrap="nowrap" sx={{ overflow: 'auto', m: 'auto', mt: 0 }}>
        {kongs.map((kong, index) => (
          <Grid item xs="auto" key={index}>
            <KongZCard
              key={kong.name}
              kong={kong}
              borderColor={ProjectColors.MAIN}
              components={[
                <CardActions key={`select-for-fuse-action`} sx={{ m: 0, p: 0, pt: 0.5 }}>
                  {resolveButton(kong)}
                </CardActions>,
              ]}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
