import React from 'react';
import { Props } from './hyper.types';
import hyperImage from './hyperium.png';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { ProjectColors } from '../../themes/mainTheme';
import { CARD_ACTION_DEFAULT_ELEMENT_WIDTH } from '../../constants/components';

export const Hyper: React.FunctionComponent<Props> = ({ amount, hideName = false, centered = false, tooltip = '' }) => {
  const text = (hideName ? '' : 'Hyperium > ') + amount;
  const display = centered ? 'inline-flex' : 'flex';

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: CARD_ACTION_DEFAULT_ELEMENT_WIDTH,
      borderColor: ProjectColors.HYPER,
    },
  });

  return (
    <StyledTooltip title={tooltip} disableInteractive hidden={tooltip === ''}>
      <Box sx={{ display: display }}>
        <img alt="hyper" src={hyperImage} width="32" height="46" style={{ marginLeft: 0, marginRight: 6 }} />
        <Typography variant="body2" sx={{ color: ProjectColors.HYPER, m: 'auto', ml: 0, mr: 0 }}>
          {text}
        </Typography>
      </Box>
    </StyledTooltip>
  );
};
