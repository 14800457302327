import React, { ReactNode } from 'react';
import { Props } from './mobileKongs.types';
import { Grid, Pagination } from '@mui/material';
import { KongCard } from '../kongCard';
import { ProjectColors } from '../../themes/mainTheme';

export const MobileKongs = <T extends object>({ rows, transformToCard }: Props<T> & { children?: ReactNode }) => {
  const cardsPerPage = 20;
  const [pageNumber, setPageNumber] = React.useState(1);

  const totalPages = Math.ceil(rows.length / cardsPerPage);

  const currentPageCards = () => {
    const from = (pageNumber - 1) * cardsPerPage;
    const to = from + cardsPerPage > rows.length ? rows.length : from + cardsPerPage;

    return rows.slice(from, to).map((row) => transformToCard(row));
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPageNumber(newPage);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {rows.length > 0 &&
          currentPageCards().map((cardData) => (
            <Grid key={cardData.tokenId} item sm={4} xs={6} md={4}>
              <KongCard
                key={cardData.name + '-mobile-card'}
                scaled
                kong={cardData}
                borderColor={ProjectColors.WHITE}
                details={cardData.details}
                link={cardData.link}
              />
            </Grid>
          ))}
      </Grid>
      <Pagination count={totalPages} page={pageNumber} onChange={handleChangePage} siblingCount={0} />
    </>
  );
};
