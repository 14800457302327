export type Props = {
  isActive: boolean;
  name: MenuIcon;
};

export enum MenuIcon {
  KONG_ARCHIVE,
  MAIN_HUB,
  LOCK,
  FUSION_LAB,
  MARKETPLACE,
}
