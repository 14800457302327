import React from 'react';
import { Props } from './fusersCollectionInfo.types';
import { CollectionInfo } from '../../../components/collectionInfo';

export const FusersCollectionInfo: React.FunctionComponent<Props> = ({ tokensMinted, currentlyMining }) => {
  const tokensMintedDetail = { label: 'Tokens minted', value: tokensMinted };
  const totalVolumeDetail = { label: 'Total volume', value: `N/A` };
  const currentlyMiningDetail = {
    label: 'Currently mining',
    value: `${currentlyMining < 0 ? 'Coming soon...' : currentlyMining}`,
  };

  return (
    <CollectionInfo
      details={{
        firstDetail: tokensMintedDetail,
        secondDetail: totalVolumeDetail,
        thirdDetail: currentlyMiningDetail,
      }}
    />
  );
};
