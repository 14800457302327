import React from 'react';
import { Props } from './fusionSuccessModal.types';
import { ProjectColors } from '../../../themes/mainTheme';
import { Box, Modal, Typography } from '@mui/material';

export const FusionSuccessModal: React.FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: `2px solid ${ProjectColors.MAIN}`,
    boxShadow: 24,
    p: 4,
    maxWidth: '470px',
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <Typography id="fusion-modal-title" variant="h6" component="h2">
          Fusion core activated
        </Typography>
        <Typography id="fusion-modal-description-p1" sx={{ mt: 2 }}>
          Congratulations!
        </Typography>
        <Typography id="fusion-modal-description-p2" sx={{ mt: 1 }}>
          You have activated fusion core, and your KongX is baking in the oven. You will be able to see your
          pre-revealed KongX in a separate page soon, from where you'll be able to mint your actual KongX NFT. After
          that it will appear in your IMX wallet.
        </Typography>
      </Box>
    </Modal>
  );
};
