import React from 'react';
import { KongXFiltersData, Props } from './kongXFilters.types';
import { Grid } from '@mui/material';
import { InputField } from '../../../../components/textField';
import { FuserType } from '../../../../constants/kongs';
import { AccordionWrapper } from '../../../../components/accordionWrapper';

export const KongXFilters: React.FunctionComponent<Props> = ({ filters, setFilters }) => {
  const handleChange = (prop: keyof KongXFiltersData) => (filter: number | string) => {
    setFilters({ ...filters, [prop]: filter === '' ? undefined : filter });
  };

  const optionAny = { value: '', label: 'Any' };
  const fuserOptions = [FuserType.KONGZ, FuserType.KONGZ_LEGEND].map((type) => ({
    value: type.toString(),
    label: type.toString(),
  }));
  const typeDropdownOptions = [...fuserOptions, optionAny];

  const gridItem = (element: React.ReactNode, smallGridColumnNumber: number) => (
    <Grid item sm={smallGridColumnNumber} xs={12}>
      {element}
    </Grid>
  );

  return (
    <AccordionWrapper summary="Filters">
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {gridItem(<InputField label="Token ID" numbersOnly onChange={handleChange('tokenId')} />, 4)}
        {gridItem(
          <InputField
            label="Fuser type"
            onChange={handleChange('fuserType')}
            options={typeDropdownOptions}
            defaultValue={''}
            isUncontrolled
          />,
          4
        )}
        {gridItem(
          <InputField
            label="Fuser ID"
            numbersOnly
            onChange={handleChange('fuserId')}
            isUncontrolled
          />,
          4
        )}
        {gridItem(
          <InputField
            numbersOnly
            label="Exonite (more than)"
            onChange={handleChange('exoniteMore')}
            isUncontrolled
          />,
          6
        )}
        {gridItem(
          <InputField
            numbersOnly
            label="Exonite (less than)"
            onChange={handleChange('exoniteLess')}
            isUncontrolled
          />,
          6
        )}
      </Grid>
    </AccordionWrapper>
  );
};
