import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { Props } from './page.types';

export const Page: React.FunctionComponent<Props> = ({ component }) => {
  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
      <Toolbar />
      {component}
    </Box>
  );
};
