import axios from 'axios';
import {
  KONGZ_API_KONG_TO_USER_CURRENCY_TRANSFER_URL,
  KONGZ_API_USER_TO_KONG_CURRENCY_TRANSFER_URL,
  KONGZ_API_FUSION_URL,
  KONGZ_API_MESSAGE_URL,
  KONGZ_API_REGISTER_URL,
  KONGZ_API_START_MINING_URL,
  KONGZ_API_CONNECT_DISCORD_USER_URL,
  KONGZ_API_PURCHASE_WHITELIST,
  KONGZ_API_WHITELIST_URL,
  KONGZ_API_ADD_WHITELIST_URL,
} from '../../constants/kongzApiUrls';
import { createWeb3HelperCheckingNetwork } from '../Web3Helper';
import {
  KongToUserTransferData,
  Currency,
  KONG_TYPE_TO_API_TYPE,
  UserToKongTransferData,
  FusionData,
  MiningData,
  WhitelistPurchaseData,
} from '../../models/kongzApiTypes';
import { KongType } from '../../constants/kongs';
import { AddWhitelistRequestData, EditWhitelistRequestData } from '../../models/whitelistApiTypes';

export enum Action {
  REGISTRATION,
  START_MINING,
  KONG_TO_USER_CURRENCY_TRANSFER,
  USER_TO_KONG_CURRENCY_TRANSFER,
  REQUEST_FUSION,
  CONNECT_DISCORD_ACCOUNT,
  PURCHASE_WHITELIST,
  ADD_WHITELIST,
  EDIT_WHITELIST,
  DELETE_WHITELIST,
}

class KongzSignedApiHelper {
  private static instance: KongzSignedApiHelper;

  public static getInstance(): KongzSignedApiHelper {
    if (!KongzSignedApiHelper.instance) {
      KongzSignedApiHelper.instance = new KongzSignedApiHelper();
    }

    return KongzSignedApiHelper.instance;
  }

  public async registerUser(wallet: string) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.REGISTRATION);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    await axios.post(KONGZ_API_REGISTER_URL, undefined, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  public async enableMining(wallet: string, currency: Currency, miningData: MiningData) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.START_MINING);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    await axios.post(KONGZ_API_START_MINING_URL(currency), miningData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  public async currencyKongToUser(
    kongType: KongType,
    kongId: string,
    wallet: string,
    currency: Currency,
    amount: number
  ) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.KONG_TO_USER_CURRENCY_TRANSFER);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    const transferData: KongToUserTransferData = {
      amount: amount,
      fromId: kongId,
      fromType: KONG_TYPE_TO_API_TYPE[kongType],
    };

    await axios.post(KONGZ_API_KONG_TO_USER_CURRENCY_TRANSFER_URL(currency), transferData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  public async currencyUserToKong(
    kongType: KongType,
    kongId: string,
    wallet: string,
    currency: Currency,
    amount: number
  ) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.USER_TO_KONG_CURRENCY_TRANSFER);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    const transferData: UserToKongTransferData = {
      amount: amount,
      toId: kongId,
      toType: KONG_TYPE_TO_API_TYPE[kongType],
    };

    await axios.post(KONGZ_API_USER_TO_KONG_CURRENCY_TRANSFER_URL(currency), transferData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  public async fuse(fuserTypeA: KongType, fuserIdA: number, fuserTypeB: KongType, fuserIdB: number, wallet: string) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.REQUEST_FUSION);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    const fusionData: FusionData = {
      firstType: KONG_TYPE_TO_API_TYPE[fuserTypeA],
      firstId: fuserIdA.toString(),
      secondType: KONG_TYPE_TO_API_TYPE[fuserTypeB],
      secondId: fuserIdB.toString(),
    };

    await axios.post(KONGZ_API_FUSION_URL, fusionData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  public async connectUserToDiscordUser(wallet: string, discordUser: number) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.CONNECT_DISCORD_ACCOUNT);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    await axios.post(
      KONGZ_API_CONNECT_DISCORD_USER_URL(discordUser),
      {},
      {
        headers: {
          signature: signature,
          message: message,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  public async purchaseWhitelist(wallet: string, whitelistId: string, address: string) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.PURCHASE_WHITELIST);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');
    const purchaseData: WhitelistPurchaseData = {
      purchaseFor: address,
    };

    await axios.post(KONGZ_API_PURCHASE_WHITELIST(whitelistId), purchaseData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  public async addWhitelist(wallet: string, addWhitelistRequestData: AddWhitelistRequestData) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.ADD_WHITELIST);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    const formData = new FormData();

    formData.append('whitelistImage', addWhitelistRequestData.imageFile);
    formData.append('whitelistData', JSON.stringify(addWhitelistRequestData));

    await axios.post(KONGZ_API_ADD_WHITELIST_URL, formData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async editWhitelist(wallet: string, whitelistId: string, editWhitelistRequestData: EditWhitelistRequestData) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.EDIT_WHITELIST);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    const formData = new FormData();

    if (editWhitelistRequestData.imageFile !== null) {
      formData.append('whitelistImage', editWhitelistRequestData.imageFile);
    }

    formData.append('whitelistData', JSON.stringify(editWhitelistRequestData));

    await axios.put(KONGZ_API_WHITELIST_URL(whitelistId), formData, {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async deleteWhitelist(wallet: string, whitelistId: string) {
    const web3Helper = await createWeb3HelperCheckingNetwork();
    const message = await KongzSignedApiHelper.getMessage(wallet, Action.DELETE_WHITELIST);
    const signature = await web3Helper.client.eth.personal.sign(message, wallet, '');

    await axios.delete(KONGZ_API_WHITELIST_URL(whitelistId), {
      headers: {
        signature: signature,
        message: message,
        'Content-Type': 'application/json',
      },
    });
  }

  private static async getMessage(wallet: string, action: Action) {
    const response = await axios.get(KONGZ_API_MESSAGE_URL, {
      params: { wallet: wallet, action: Action[action] },
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data.message;
  }
}

export default KongzSignedApiHelper.getInstance();
