import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Props } from './kongXPage.types';
import { KongXData } from '../../models/kongzApiTypes';
import KongZApiHelper from '../../helpers/apiImmKongz/KongZApiHelper';
import { KongXDetails } from './kongXDetails';
import { NotFound } from '../notFound/notFound';

export const KongXPage: React.FunctionComponent<Props> = () => {
  const { id } = useParams();
  const [kongX, setKongX] = useState<KongXData | undefined>(undefined);

  useEffect(() => {
    getKongXData();
  }, []);

  const getKongXData = async () => {
    if (id === undefined) {
      return;
    }

    setKongX(await KongZApiHelper.getKongXData(+id));
  };

  //TODO only on error show 404
  return kongX !== undefined ? <KongXDetails kongX={kongX} /> : <NotFound />;
};
