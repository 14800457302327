import { WhitelistPurchaseData } from '../whitelistCard';

export type Props = {
  rows: WhitelistPurchaseData[];
};

export type PageSize = 25 | 50 | 100;
export const allPageSizes: PageSize[] = [25, 50, 100];

export const DISCORD_ID_LOOKUP_URL = 'https://discord.id/';

export enum Column {
  USER = 'Purchased by',
  DISCORD_ID = 'Discord',
  PURCHASED_FOR = 'Purchased for',
  PURCHASED_AT = 'Purchased At',
  WHITELIST_NAME = 'Whitelist Name',
  SPOTS_LEFT = 'Spots left',
}

export enum ColumnMobile {
  PURCHASED_FOR = 'For',
  PURCHASED_AT = 'At',
  WHITELIST_NAME = 'Whitelist',
}
