import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { ARCHIVE_TAB_STORAGE_KEY, ArchiveTabName, KongArchiveTab, Props } from './kongArchive.types';
import { AllKongZ } from './allKongZ';
import KongZApiHelper from '../../helpers/apiImmKongz/KongZApiHelper';
import {
  KongXDataWithBalances,
  KongZDataWithBalances,
  LegendDataWithBalances
} from '../../models/kongzApiTypes';
import { AllLegends } from './allLegends';
import { AllKongX } from './allKongX';

export const KongArchive: React.FunctionComponent<Props> = () => {
  const [allKongZ, setAllKongZ] = React.useState<KongZDataWithBalances[]>([]);
  const [allLegends, setAllLegends] = React.useState<LegendDataWithBalances[]>([]);
  const [allKongX, setAllKongX] = React.useState<KongXDataWithBalances[]>([]);

  React.useEffect(() => {
    fillAllKongZ();
    fillAllLegends();
    fillAllKongX();
  }, []);

  const fillAllKongZ = async () => {
    const retrievedAllKongz = await KongZApiHelper.getAllKongZData();
    setAllKongZ(retrievedAllKongz);
  };

  const fillAllLegends = async () => {
    const retrievedAllLegends = await KongZApiHelper.getAllLegendsData();
    setAllLegends(retrievedAllLegends);
  };

  const fillAllKongX = async () => {
    const retrievedAllKongX = await KongZApiHelper.getAllKongXData();
    setAllKongX(retrievedAllKongX);
  };

  const tabs: KongArchiveTab[] = [
    { name: 'KongZ', component: <AllKongZ kongzDataList={allKongZ} /> },
    { name: 'Legends', component: <AllLegends legendDataList={allLegends} /> },
    { name: 'KongX', component: <AllKongX kongXDataList={allKongX} /> },
  ];

  const storedTab = localStorage.getItem(ARCHIVE_TAB_STORAGE_KEY);
  const prevTab = storedTab ? (storedTab as ArchiveTabName) : 'KongZ';
  const [activeTabIndex, setActiveTabIndex] = React.useState(tabs.map((tab) => tab.name).indexOf(prevTab));

  const handleChange = (_: React.SyntheticEvent, selectedTabIndex: number) => {
    setActiveTabIndex(selectedTabIndex);
    localStorage.setItem(ARCHIVE_TAB_STORAGE_KEY, tabs[selectedTabIndex].name);
  };

  return (
    <Box sx={{ paddingTop: 0 }}>
      <Box>
        <Tabs value={activeTabIndex} onChange={handleChange} textColor="primary" indicatorColor="secondary" centered>
          {tabs.map((tab, index) => (
            <Tab key={`archive-tab-name-${tab.name}`} value={index} label={tab.name} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <Box key={`archive-tab-${tab.name}`} hidden={index !== activeTabIndex} sx={{ paddingTop: 3 }}>
          {tab.component}
        </Box>
      ))}
    </Box>
  );
};
