import { Alert, AlertColor, Box, Snackbar, Tab, Tabs } from '@mui/material';
import React from 'react';
import { MarketplaceTab, Props } from './marketplace.types';
import { AppContext } from '../../global/context/appContext';
import { NOTIFICATION_TIMEOUT } from '../../constants/components';
import { WhitelistCardData, WhitelistPurchaseData } from '../../components/whitelistCard';
import KongZApiHelper from '../../helpers/apiImmKongz/KongZApiHelper';
import { whitelistDataToWhitelistCardData, whitelistsDataToWhitelistPurchasesData } from '../../helpers/transformers';
import { AllWhitelists } from './allWhitelists';
import { AllReports } from './allReports';
import { UserRole } from '../../models/kongzApiTypes';

export const Marketplace: React.FunctionComponent<Props> = () => {
  const { user, updateUser } = React.useContext(AppContext);
  const [notification, setNotification] = React.useState<React.ReactElement | undefined>(undefined);
  const [allWhitelists, setAllWhitelists] = React.useState<WhitelistCardData[]>([]);
  const [allWhitelistPurchases, setAllWhitelistPurchases] = React.useState<WhitelistPurchaseData[]>([]);

  React.useEffect(() => {
    fillAllWhitelistCards();
  }, []);

  React.useEffect(() => {
    setAllTabs(tabs());
  }, [allWhitelists]);

  React.useEffect(() => {
    setAllTabs(tabs());
    updateActiveTab();
  }, [user]);

  const onWhitelistPurchaseSuccess = (whitelist: WhitelistCardData) => {
    const success = createNotification(
      'success',
      `Whitelist for ${whitelist.name} successfully purchased`
    );
    setNotification(success);
    updateUser();
    fillAllWhitelistCards();
  };

  const onWhitelistPurchaseFailure = (whitelist: WhitelistCardData, reason: string) => {
    const failure = createNotification(
      'error',
      `Failed to purchase whitelist for ${whitelist.name}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const onWhitelistAddSuccess = () => {
    const success = createNotification(
      'success',
      `Whitelist successfully created`
    );
    setNotification(success);
    fillAllWhitelistCards();
  };

  const onWhitelistAddFailure = (reason: string) => {
    const failure = createNotification(
      'error',
      `Failed to add whitelist. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const onWhitelistEditSuccess = (whitelist: WhitelistCardData) => {
    const success = createNotification(
      'success',
      `Whitelist ${whitelist.name} successfully updated`
    );
    setNotification(success);
    fillAllWhitelistCards();
  };

  const onWhitelistEditFailure = (whitelist: WhitelistCardData, reason: string) => {
    const failure = createNotification(
      'error',
      `Failed to edit whitelist ${whitelist.name}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const onWhitelistDeleteSuccess = (whitelist: WhitelistCardData) => {
    const success = createNotification(
      'success',
      `Whitelist ${whitelist.name} successfully deleted`
    );
    setNotification(success);
    fillAllWhitelistCards();
  };

  const onWhitelistDeleteFailure = (whitelist: WhitelistCardData, reason: string) => {
    const failure = createNotification(
      'error',
      `Failed to delete whitelist ${whitelist.name}. Reason: ${reason}`
    );
    setNotification(failure);
  };

  const closeNotification = () => setNotification(undefined);

  const createNotification = (severityValue: AlertColor, text: string) => {
    return (
      <Alert onClose={closeNotification} severity={severityValue} variant="filled" sx={{ width: '100%' }}>
        {text}
      </Alert>
    );
  };

  const fillAllWhitelistCards = async () => {
    const retrievedWhitelists = await KongZApiHelper.getAllWhitelists();
    const nonDeletedWhitelists = retrievedWhitelists.filter((whitelistData) => {
      return !whitelistData.isDeleted;
    });

    setAllWhitelists(nonDeletedWhitelists.map((data) => whitelistDataToWhitelistCardData(data)));
    setAllWhitelistPurchases(whitelistsDataToWhitelistPurchasesData(retrievedWhitelists));
  };

  const tabs = (): MarketplaceTab[] => {
    const marketplaceTabs: MarketplaceTab[] = [
      {
        name: 'Whitelists',
        component: <AllWhitelists
          allWhitelists={allWhitelists}
          onWhitelistAddSuccess={onWhitelistAddSuccess}
          onWhitelistAddFailure={onWhitelistAddFailure}
          onWhitelistPurchaseSuccess={onWhitelistPurchaseSuccess}
          onWhitelistPurchaseFailure={onWhitelistPurchaseFailure}
          onWhitelistEditSuccess={onWhitelistEditSuccess}
          onWhitelistEditFailure={onWhitelistEditFailure}
          onWhitelistDeleteSuccess={onWhitelistDeleteSuccess}
          onWhitelistDeleteFailure={onWhitelistDeleteFailure}
        />
      },
    ];

    if (user === undefined || !user.roles.includes(UserRole.IS_WHITELIST_MANAGER)) {
      return marketplaceTabs;
    }

    marketplaceTabs.push(
      {
        name: 'Purchase Reports',
        component: <AllReports
          allWhitelistPurchases={allWhitelistPurchases}
        />
      }
    )

    return marketplaceTabs;
  }

  const updateActiveTab = () => {
    if (user === undefined || !user.roles.includes(UserRole.IS_WHITELIST_MANAGER)) {
      setActiveTabIndex(0);
    }
  }

  const [activeTabIndex, setActiveTabIndex] = React.useState(tabs().map((tab) => tab.name).indexOf("Whitelists"));
  const [allTabs, setAllTabs] = React.useState(tabs());

  const handleChange = (_: React.SyntheticEvent, selectedTabIndex: number) => {
    setActiveTabIndex(selectedTabIndex);
  };

  return (
    <div>
      <Snackbar open={notification !== undefined} autoHideDuration={NOTIFICATION_TIMEOUT} onClose={closeNotification}>
        {notification}
      </Snackbar>
      <Box>
        <Tabs value={activeTabIndex} onChange={handleChange} textColor="primary" indicatorColor="secondary" centered>
          {allTabs.map((tab, index) => (
            <Tab key={`marketplace-tab-name-${tab.name}`} value={index} label={tab.name} />
          ))}
        </Tabs>
      </Box>
      {allTabs.map((tab, index) => (
        <Box key={`marketplace-tab-${tab.name}`} hidden={index !== activeTabIndex} sx={{ paddingTop: 3 }}>
          {tab.component}
        </Box>
      ))}
    </div>
  );
};
