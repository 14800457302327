import React from 'react';
import { Props } from './inputField.types';
import { MenuItem, TextField as TextFieldMui } from '@mui/material';

export const InputField: React.FunctionComponent<Props> = ({
  label,
  numbersOnly = false,
  onChange,
  options = [],
  color,
  isUncontrolled = false,
  defaultValue,
  minWidth = '100%',
  multiLineOptions,
  inputProps,
}) => {
  const sx = {
    minWidth: minWidth,
    '& .MuiInputBase-input': { height: 'auto' },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: color,
      },
    },
    '& label.Mui-focused': {
      color: color,
    },
    '& label': {
      color: color,
    },
  };

  return (
    <TextFieldMui
      sx={sx}
      label={label}
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
      size="medium"
      type={numbersOnly !== undefined && numbersOnly ? 'number' : 'search'}
      fullWidth
      multiline={multiLineOptions !== undefined}
      maxRows={multiLineOptions !== undefined ? multiLineOptions.maxRows : undefined}
      inputProps={inputProps}
      defaultValue={isUncontrolled ? defaultValue : undefined}
      value={isUncontrolled ? undefined : defaultValue}
      select={options.length > 0}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextFieldMui>
  );
};
