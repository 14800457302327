import React from 'react';
import { Props } from './discordMobileMenu.types';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FaDiscord } from 'react-icons/all';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ProjectColors } from '../../../themes/mainTheme';
import { AppContext } from '../../../global/context/appContext';
import DiscordApiHelper from '../../../helpers/DiscordApiHelper';
import {DISCORD_AUTHORIZE_URL, DISCORD_SERVER_URL, DiscordButton} from '../../../constants/discord';

export const DiscordMobileMenu: React.FunctionComponent<Props> = ({ isConnected, wallet}) => {
  const { user } = React.useContext(AppContext);

  const connectToDiscord = async () => {
    const state = DiscordApiHelper.generateDiscordAuthorizationState();
    const discordAuthorizationUrl = DISCORD_AUTHORIZE_URL + `&state=${btoa(state)}`;

    window.open(discordAuthorizationUrl, "_self");
  };

  const openDiscord = () => {
    window.open(DISCORD_SERVER_URL, "_blank");
  };

  const [buttonColor, setButtonColor] = React.useState(ProjectColors.WHITE);
  const [openSubmenu, setOpenSubmenu] = React.useState(false);
  const handleOpenSubmenu = () => {
    setOpenSubmenu(!openSubmenu);
  };
  const submenuListStyle = {pl: 4};
  const listItemStyle = {paddingLeft: '14px'};
  const listIconStyle = {paddingRight: '32px'};
  const discordIconStyle = {width: '2em', height: '1.3em'};

  React.useEffect(() => {
    if (isConnected) {
      setButtonColor(ProjectColors.SECONDARY);
    }
  }, [user, isConnected]);

  return (
    <><ListItemButton sx={listItemStyle} onClick={handleOpenSubmenu}>
      <ListItemIcon sx={listIconStyle}>
        <FaDiscord color={buttonColor} style={discordIconStyle}/>
      </ListItemIcon>
      <ListItemText primary={DiscordButton.DISCORD}/>
      {openSubmenu ? <ExpandLess/> : <ExpandMore/>}
    </ListItemButton><Collapse in={openSubmenu} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={submenuListStyle} onClick={openDiscord}>
          <ListItemText primary={DiscordButton.OPEN}/>
        </ListItemButton>
        {wallet && !isConnected && (
          <ListItemButton sx={submenuListStyle} onClick={connectToDiscord}>
            <ListItemText primary={DiscordButton.CONNECT}/>
          </ListItemButton>
        )}
        {isConnected && (
          <ListItemButton sx={submenuListStyle} onClick={connectToDiscord}>
            <ListItemText primary={DiscordButton.CONNECT_NEW}/>
          </ListItemButton>
        )}
      </List>
    </Collapse></>
  );
};
