import React from 'react';
import { useNavigate } from 'react-router-dom';
import { KONG_ARCHIVE_ROUTE } from '../../constants/routes';
import DiscordApiHelper from '../../helpers/DiscordApiHelper';
import { AppContext } from '../../global/context/appContext';
import {Alert, AlertColor, Box, CircularProgress, Snackbar, TextField, Typography} from '@mui/material';
import { NOTIFICATION_TIMEOUT } from '../../constants/components';
import {ConnectionState} from "../../constants/discord";

export const Discord: React.FunctionComponent = () => {
  const { user, updateUser } = React.useContext(AppContext);
  const [notification, setNotification] = React.useState<React.ReactElement | undefined>(undefined);
  const [connected, setConnected] = React.useState<boolean | undefined>(undefined);
  const [connectionState, setConnectionState] = React.useState<string>(ConnectionState.CONNECTING);

  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(KONG_ARCHIVE_ROUTE);
    }
  };

  const closeNotification = () => setNotification(undefined);

  const createNotification = (severityValue: AlertColor, text: string) => {
    return (
      <Alert onClose={closeNotification} severity={severityValue} variant="filled" sx={{ width: '100%' }}>
        {text}
      </Alert>
    );
  };
  const onDiscordConnectSuccess = () => {
    const success = createNotification(
      'success',
      `Successfully connected to Discord`
    );
    setNotification(success);
    setConnectionState(ConnectionState.CONNECTED);
    updateUser();
  };
  const onDiscordConnectFailure = () => {
    const failure = createNotification(
      'error',
      `Failed to connect to Discord`
    );
    setNotification(failure);
    setConnectionState(ConnectionState.FAILED_TO_CONNECT);
    updateUser();
  };

  React.useEffect(() => {
    if (connected !== undefined) {
      return;
    }

    authorizeUser();
  }, [user]);

  React.useEffect(() => {
    if (connected === undefined) {
      return;
    }

    const timer = setTimeout(() => {
      navigateBack()
    }, 2000);
    return () => clearTimeout(timer);
  }, [connected]);

  const authorizeUser = async () => {
    if (user === undefined) {
      return;
    }

    try {
      await DiscordApiHelper.authorizeDiscordUser(user.wallet)
        .then(() => onDiscordConnectSuccess())
        .then(() => setConnected(true));
    } catch (e: any) {
      onDiscordConnectFailure();
      setConnected(false);
    }
  };

  const connectionStateStyle = {textAlign: "center"}

  return (
    <div>
      <Snackbar open={notification !== undefined} autoHideDuration={NOTIFICATION_TIMEOUT} onClose={closeNotification}>
        {notification}
      </Snackbar>
      <h1>
        <Typography variant="h5" sx={connectionStateStyle}>
          {connectionState}
        </Typography>
      </h1>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    </div>
  );
};
