import React from 'react';
import { Props } from './accordionWrapper.types';
import { AppContext } from '../../global/context/appContext';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ProjectColors } from '../../themes/mainTheme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AccordionWrapper: React.FunctionComponent<Props> = ({ summary, children }) => {
  const { isMobile } = React.useContext(AppContext);

  return isMobile ? (
    <Accordion
      sx={{
        borderBottom: `1px solid ${ProjectColors.MAIN}`,
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  ) : (
    <>{children}</>
  );
};
