import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { IsDiscordRequiredModalOptions, Props } from './manageWhitelistModal.types';
import { AppContext } from '../../global/context/appContext';
import { InputField } from '../textField';
import { Currency, CURRENCY_COLOR, CURRENCY_NAME } from '../../models/kongzApiTypes';
import { MODAL_STYLES } from '../../constants/components';
import { ProjectColors } from '../../themes/mainTheme';
import { FileField } from '../fileField';
import { AddWhitelistRequestData, EditWhitelistRequestData } from '../../models/whitelistApiTypes';

export const ManageWhitelistModal: React.FunctionComponent<Props> = ({
  whitelist,
  isOpen,
  onClose,
  onApprove,
}) => {
  const { isMobile } = React.useContext(AppContext);

  const [whitelistCurrency, setWhitelistCurrency] = React.useState(
    whitelist !== undefined ? whitelist.currency : Currency.EXONITE
  );
  const [whitelistImage, setWhitelistImage] = React.useState<File | null>(null);
  const [whitelistName, setWhitelistName] = React.useState(whitelist !== undefined ? whitelist.name : '');
  const [whitelistDescription, setWhitelistDescription] = React.useState(
    whitelist !== undefined ? whitelist.description : ''
  );
  const [spotPrice, setSpotPrice] = React.useState(whitelist !== undefined ? whitelist.spotPrice : 0);
  const [totalSpots, setTotalSpots] = React.useState(whitelist !== undefined ? whitelist.totalSpots : 0);
  const [isDiscordRequired, setIsDiscordRequired] = React.useState(
    whitelist !== undefined ? String(whitelist.isDiscordRequired) : 'true'
  );
  const [discordLink, setDiscordLink] = React.useState(whitelist !== undefined ? whitelist.discordLink : undefined);
  const [websiteLink, setWebsiteLink] = React.useState(whitelist !== undefined ? whitelist.websiteLink : undefined);
  const [twitterLink, setTwitterLink] = React.useState(whitelist !== undefined ? whitelist.twitterLink : undefined);

  const modalColor = whitelist !== undefined ? CURRENCY_COLOR[whitelist.currency] : ProjectColors.MAIN;
  const maxNameLength = 40;
  const maxDescriptionLength = 200;
  const maxLinkLength = 250;

  const isNameValid = () => whitelistName.length > 0;
  const isDescriptionValid = () => whitelistDescription.length > 0;
  const isSpotPriceValid = () => spotPrice > 0;
  const isTotalSpotsValid = () => totalSpots > 0;
  const isImageValid = () => whitelistImage !== null;
  const isEditFormValid = () => isTotalSpotsValid() && isSpotPriceValid() && isDescriptionValid() && isNameValid();
  const isAddFormValid = () => isEditFormValid() && isImageValid();

  const invalidDataWarning = () => {
    const isFormValid = whitelist !== undefined ? isEditFormValid() : isAddFormValid();

    return isFormValid ? (
      <></>
    ) : (
      <Typography color={ProjectColors.RED} fontSize="0.6rem">
        Invalid data
      </Typography>
    );
  };

  const performSaving = () => {
    if (whitelist !== undefined) {
      const editWhitelistData: EditWhitelistRequestData = {
        uniqueId: whitelist.id,
        name: whitelistName,
        description: whitelistDescription,
        spotPrice: spotPrice,
        totalSpots: totalSpots,
        currency: whitelistCurrency,
        imageFile: whitelistImage,
        isDiscordRequired: isDiscordRequired === 'true',
        discordLink: discordLink === undefined || discordLink === '' ? null : discordLink,
        websiteLink: websiteLink === undefined || websiteLink === '' ? null : websiteLink,
        twitterLink: twitterLink === undefined || twitterLink === '' ? null : twitterLink,
      }

      onApprove(editWhitelistData);
    } else {
      if (null !== whitelistImage) {
        const addWhitelistData: AddWhitelistRequestData = {
          name: whitelistName,
          description: whitelistDescription,
          spotPrice: spotPrice,
          totalSpots: totalSpots,
          currency: whitelistCurrency,
          imageFile: whitelistImage,
          isDiscordRequired: isDiscordRequired === 'true',
          discordLink: discordLink === undefined || discordLink === '' ? null : discordLink,
          websiteLink: websiteLink === undefined || websiteLink === '' ? null : websiteLink,
          twitterLink: twitterLink === undefined || twitterLink === '' ? null : twitterLink,
        }

        onApprove(addWhitelistData);
      }
    }
  };

  const currencyDropdownOptions = Object.values(Currency).map((label) => {
    return { value: label, label: label };
  });

  const buttonMarginY = 1.5;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={MODAL_STYLES(whitelist !== undefined ? CURRENCY_COLOR[whitelist.currency] : ProjectColors.MAIN, isMobile)}
      >
        <Typography id="currency-transfer-modal-title" variant="h6" component="h2">
          {whitelist !== undefined ? 'Edit whitelist' : 'Add Whitelist'}
        </Typography>
        <Typography id="currency-transfer-modal-description" sx={{ mt: 2 }}>
          {whitelist !== undefined ? `Update Whitelist ${whitelist.name} details` : 'Add Whitelist details'}
        </Typography>
        <Box sx={{ pt: 2 }}>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              label={'Whitelist currency'}
              onChange={(event) => setWhitelistCurrency(CURRENCY_NAME[event])}
              options={currencyDropdownOptions}
              defaultValue={whitelistCurrency}
              color={modalColor}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <FileField
              label={'Whitelist image'}
              onChange={(event) => setWhitelistImage(event !== null && event.files !== null ? event.files[0] : null)}
              color={modalColor}
              minWidth="20%"
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              label={'Whitelist name'}
              onChange={(event) => setWhitelistName(String(event))}
              defaultValue={whitelistName}
              color={modalColor}
              inputProps={{ maxLength: maxNameLength }}
              multiLineOptions={{ maxRows: 2 }}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              label={'Whitelist description'}
              onChange={(event) => setWhitelistDescription(String(event))}
              defaultValue={whitelistDescription}
              color={modalColor}
              inputProps={{ maxLength: maxDescriptionLength }}
              multiLineOptions={{ maxRows: 7 }}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              numbersOnly
              label={'Whitelist spot price'}
              onChange={(event) => setSpotPrice(+event)}
              defaultValue={spotPrice}
              color={modalColor}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              numbersOnly
              label={'Whitelist total spots'}
              onChange={(event) => setTotalSpots(+event)}
              defaultValue={totalSpots}
              color={modalColor}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              label={'Is Discord Required'}
              onChange={(event) => setIsDiscordRequired(String(event))}
              options={IsDiscordRequiredModalOptions}
              defaultValue={isDiscordRequired}
              color={modalColor}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              label={'Discord Link'}
              onChange={(event) => setDiscordLink(String(event))}
              defaultValue={discordLink}
              color={modalColor}
              inputProps={{ maxLength: maxLinkLength }}
              multiLineOptions={{ maxRows: 5 }}
            />
          </Box>
          <Box sx={{ my: buttonMarginY }}>
            <InputField
              label={'Website Link'}
              onChange={(event) => setWebsiteLink(String(event))}
              defaultValue={websiteLink}
              color={modalColor}
              inputProps={{ maxLength: maxLinkLength }}
              multiLineOptions={{ maxRows: 5 }}
            />
          </Box>
          <InputField
            label={'Twitter Link'}
            onChange={(event) => setTwitterLink(String(event))}
            defaultValue={twitterLink}
            color={modalColor}
            inputProps={{ maxLength: maxLinkLength }}
            multiLineOptions={{ maxRows: 5 }}
          />
          <Box sx={{ display: 'inline-flex', pt: 2 }}>
            <Button
              variant="contained"
              disabled={whitelist !== undefined ? !isEditFormValid() : !isAddFormValid()}
              onClick={performSaving}
              sx={{ ml: 2, pl: 3, pr: 3, backgroundColor: modalColor }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ ml: 2, pl: 3, pr: 3, color: modalColor, borderColor: modalColor }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        <Box sx={{ pt: 0.5 }}>{invalidDataWarning()}</Box>
      </Box>
    </Modal>
  );
};
