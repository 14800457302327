import {
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell as TableCellMui,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React from 'react';
import { allPageSizes, Column, KongDataTableRow, PageSize, Props } from './kongDataTable.types';
import { ProjectColors } from '../../themes/mainTheme';
import { Hyper } from '../hyper';
import { LinkToKong } from '../linkToKong';
import { TableCell } from '../tableCell';

export const KongDataTable: React.FunctionComponent<Props> = ({ rows }) => {
  const columnNames = Object.values(Column);

  const [rowsPerPage, setRowsPerPage] = React.useState<PageSize>(25);
  const [pageNumber, setPageNumber] = React.useState(0);

  const headerCell = (value: any, color: string | null = null) => {
    const textColor = color ? color : ProjectColors.SECONDARY;

    return (
      <TableCellMui
        key={value as string}
        align="center"
        sx={{ border: `2px solid ${ProjectColors.SECONDARY}`, color: `${textColor}` }}
      >
        {value}
      </TableCellMui>
    );
  };

  const card = (row: KongDataTableRow) => {
    return <img alt={row.id.toString()} src={row.imageUrl} width="100" height="100" />;
  };

  const fusions = (row: KongDataTableRow) => {
    const checkboxes = [];
    for (let i = 0; i < 4; i++) {
      const isChecked = i < row.fusions;
      checkboxes.push(
        <Checkbox key={row.id + '-' + row.rarity + '-' + i + '-fusions'} checked={isChecked} sx={{ padding: 0 }} />
      );
    }

    return <div>{checkboxes}</div>;
  };

  const currentPageRows = () => {
    const from = pageNumber === 0 ? 0 : pageNumber * rowsPerPage;
    const to = from + rowsPerPage > rows.length ? rows.length : from + rowsPerPage;

    return rows.slice(from, to);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10) as PageSize);
    setPageNumber(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '90%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columnNames.map((column) => {
              const color = column === Column.HYPERIUM_ORES ? ProjectColors.HYPER : null;
              return headerCell(column, color);
            })}
          </TableRow>
        </TableHead>
        {rows.length > 0 && (
          <TableBody>
            {currentPageRows().map((row) => (
              <TableRow key={row.id} sx={{ border: 0, height: 100, maxHeight: 100 }}>
                <TableCell value={card(row)} width={100} wrapInTypography={false} />
                <TableCell value={row.id} />
                <TableCell value={row.rarity} />
                <TableCell value={row.earring} />
                <TableCell
                  value={<Hyper amount={row.hyper} hideName centered />}
                  color={ProjectColors.HYPER}
                  wrapInTypography={false}
                />
                <TableCell value={fusions(row)} wrapInTypography={false} />
                <TableCell value={<LinkToKong url={row.url} id={row.id} inNewTab />} />
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter>
          {rows.length > 0 ? (
            <TableRow>
              <TableCellMui colSpan={columnNames.length}>
                <TablePagination
                  rowsPerPageOptions={allPageSizes}
                  component="div"
                  count={rows.length}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCellMui>
            </TableRow>
          ) : (
            <TableRow key="loading-all-kongz" sx={{ border: 0 }}>
              <TableCellMui align="center" colSpan={columnNames.length}>
                <CircularProgress />
              </TableCellMui>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
