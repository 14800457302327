import React from 'react';
import { CurrencyTransferButtonType, Props } from './currencyTransferButton.types';
import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import KongzSignedApiHelper from '../../helpers/apiImmKongz/KongzSignedApiHelper';
import { anyErrorToMessage } from '../../helpers/transformers';
import { CurrencyTransferModal } from './currencyTransferModal';
import { CURRENCY_COLOR } from '../../models/kongzApiTypes';
import { KONG_ACTION_BUTTON_MIN_HEIGHT } from '../../constants/components';
import { buttonsBoxWidth } from '../../helpers/utils';

export const CurrencyTransferButton: React.FunctionComponent<Props> = ({
  type,
  kong,
  user,
  buttonText,
  buttonsInRow = 1,
  texts,
  onSuccess,
  onFailure,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom" arrow />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
      borderColor: CURRENCY_COLOR[kong.currency],
    },
  });

  const onApprove = async (amount: number) => {
    if (user === undefined) {
      return;
    }

    try {
      switch (type) {
        case 'kong_to_user':
          await KongzSignedApiHelper.currencyKongToUser(
            kong.type,
            kong.tokenId.toString(),
            user.wallet,
            kong.currency,
            amount,
          );
          break;
        case 'user_to_kong':
          await KongzSignedApiHelper.currencyUserToKong(
            kong.type,
            kong.tokenId.toString(),
            user.wallet,
            kong.currency,
            amount,
          );
          break;
      }

      onSuccess(kong.currency, amount, user.wallet, kong.type, kong.tokenId);
    } catch (e: any) {
      onFailure(kong.currency, amount, user.wallet, kong.type, kong.tokenId, anyErrorToMessage(e));
    } finally {
      setIsModalOpen(false);
    }
  };

  const amounts = () => {
    switch (type) {
      case CurrencyTransferButtonType.KONG_TO_USER:
        return { default: kong.currencyAmount, max: kong.currencyAmount };
      case CurrencyTransferButtonType.USER_TO_KONG: {
        let userCurrencyAmount = 0;
        if (user !== undefined && user.balances !== undefined && user.balances[kong.currency] !== undefined) {
          const balance = user.balances[kong.currency];
          userCurrencyAmount = balance === undefined ? 0 : balance.amount;
        }

        return { default: userCurrencyAmount, max: userCurrencyAmount };
      }
    }
  };

  const button = () => {
    return (
      <StyledTooltip title={texts.tooltip} disableInteractive>
        <Button
          size="small"
          variant="contained"
          onClick={() => setIsModalOpen(true)}
          sx={{
            backgroundColor: CURRENCY_COLOR[kong.currency],
            minWidth: '100%',
            minHeight: KONG_ACTION_BUTTON_MIN_HEIGHT,
            pl: 0,
            pr: 0,
          }}
        >
          {buttonText}
        </Button>
      </StyledTooltip>
    );
  };

  const modal = () => {
    return (
      <CurrencyTransferModal
        currency={kong.currency}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onApprove={onApprove}
        amounts={amounts()}
        texts={{ title: texts.modalTitle, body: texts.modalBody }}
      />
    );
  };

  return (
    <Box sx={{ minWidth: buttonsBoxWidth(buttonsInRow) }}>
      {button()}
      {modal()}
    </Box>
  );
};
